import DateInput from "./components/DateInput";
import styles from "./styles.module.scss";
import {DateRangeInput} from "../../../../../../services/rostering-api";
import Step from "../Step";

export interface DateRangeViolations {
    start: Array<string>;
    end: Array<string>;
    general: Array<string>;
}

interface Props {
    violations: DateRangeViolations
    input: DateRangeInput
    onStartDateChange: (value: string) => void
    onEndDateChange: (value: string) => void
    onNext: () => void
}

export default function StepDateRange({violations, input, onStartDateChange, onEndDateChange, onNext}: Props) {
    return <Step header="Welke periode wil je laten plannen?" previousDisabled onNext={onNext}
                 errorMessages={violations.general}>
        <DateInput label="Startdatum" violations={violations.start} value={input.start} onChange={onStartDateChange}/>
        <div className={styles.container_datepicker_separator}> -</div>
        <DateInput label="Einddatum" violations={violations.end} value={input.end} onChange={onEndDateChange}/>
    </Step>
}
