import {Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {useDownloadResultSheetQuery} from "../../services/rostering-api";
import Button from "../../components/Button";
import ErrorMessages from "../../components/ErrorMessages";
import styles from "./styles.module.scss"
import {Buffer} from "buffer";
import {saveAs} from "file-saver";

export default function DownloadCalculationResult() {
    const {calculationTaskId, verificationCode} = useParams()
    if (!calculationTaskId || !verificationCode)
        throw new Error("URL path variables not resolved")

    const {error, data} = useDownloadResultSheetQuery({
        variables: {
            calculationTaskId, verificationCode
        }
    })

    const errorMessages = []
    if (error) errorMessages.push("Er is iets misgegaan bij het ophalen van jouw planning")
    if (data?.calculationTaskDownloadSolution.resultSheetRemoved === true) {
        errorMessages.push("Deze link is niet meer geldig. Je planning is inmiddels verwijderd")
    }

    const downloadResultSheet = () => {
        if (!data) throw new Error("Result sheet data not available")
        const binaryContent = data.calculationTaskDownloadSolution.content;
        if (!binaryContent) throw new Error("Binary content not available")

        const buffer = Buffer.from(binaryContent.base64Content, "base64")
        const blob: Blob = new Blob([buffer], {type: binaryContent.mediaType})
        saveAs(blob, binaryContent.suggestedFileName)
    }

    return <Container fluid>
        <Row>
            <Col xs={12} className="text-center">
                <div className={styles.container_scene}>
                    <ErrorMessages messages={errorMessages}/>
                    <div className={styles.container_button_download}>
                        <Button disabled={!data || data.calculationTaskDownloadSolution.resultSheetRemoved}
                                onClick={downloadResultSheet}>download planning</Button>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>


}
