import {ApolloClient, HttpLink, InMemoryCache, split} from "@apollo/client";
import {rosteringApiUri} from "../../config";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";
import {getMainDefinition} from "@apollo/client/utilities";

const wsLink = new GraphQLWsLink(createClient({
    url: rosteringApiUri.replace(/^http/, "ws"),
    keepAlive: 10_000,
    shouldRetry: () => true,
    retryAttempts: Infinity
}))

const httpLink = new HttpLink({
    uri: rosteringApiUri
})

const splitLink = split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
)


export const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
})
