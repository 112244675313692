import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LandingPage from "./scenes/LandingPage";
import "rc-slider/assets/index.css";
import './theme.scss'
import {Path} from "./routes";
import GenerateFillSheet from "./scenes/GenerateFillSheet";
import {ApolloProvider} from "@apollo/client";
import {apolloClient} from "./services/rostering-api/apollo-client";
import DownloadCalculationResult from "./scenes/DownloadCalculationResult";

const container = document.getElementById('root')
if (!container) throw new Error('Root element not found ')

const root = createRoot(container)
root.render(
    <ApolloProvider client={apolloClient}>
        <BrowserRouter>
            <Routes>
                <Route path={Path.LANDING_PAGE} element={<LandingPage/>}/>
                <Route path={Path.GENERATE_FILL_SHEET} element={<GenerateFillSheet/>}/>
                <Route path={Path.DOWNLOAD_CALCULATION_RESULT} element={<DownloadCalculationResult/>}/>
            </Routes>
        </BrowserRouter>
    </ApolloProvider>
)

