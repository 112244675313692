import {Modal} from "react-bootstrap";
import React from "react";
import {CreateCalculationTaskFromFillSheetMutation} from "../../../../../../services/rostering-api";

interface Props {
    loading: boolean
    creationResult?: CreateCalculationTaskFromFillSheetMutation["calculationTaskCreateFromFillSheet"]
}

export default function Header({loading, creationResult}: Props) {

    const titleText = (): string => {
        if (loading) return 'Bezig met verwerken ...'
        if (creationResult?.success) return 'Planning starten'
        switch (creationResult?.failureReason) {
            case "FILL_SHEET_DEEMED_INFECTED":
                return "Invulsheet geweigerd"
            case "FILL_SHEET_NOT_A_VALID_SPREADSHEET":
                return "Geen geldig Excel-document"
            case "FILL_SHEET_INVALID":
                return "Invulsheet bevat 1 of meer fouten"
            default:
                return "Er is iets misgegaan"
        }
    }

    return <Modal.Header closeButton>
        <Modal.Title>{titleText()}</Modal.Title>
    </Modal.Header>

}
