import styles from "./styles.module.scss";
import Button from "../../../../components/Button";
import React, {useRef} from "react";

interface Props {
    dragAndDropActive: boolean
    onDragEnter: () => void
    onDragLeave: () => void
    onUpload: (file: File) => void
}

export default function FillSheetUpload({dragAndDropActive, onDragEnter, onDragLeave, onUpload}: Props) {
    const fileInputRef = useRef<HTMLInputElement>(null)
    let dragCounter = dragAndDropActive ? 1 : 0

    const handleUploadButtonClick = () => {
        fileInputRef.current?.click()
    }

    const handleDragEnter = () => {
        dragCounter++
        if (dragCounter === 1) {
            onDragEnter()
        }
    }

    const handleDragLeave = () => {
        dragCounter--
        if (dragCounter === 0) {
            onDragLeave()
        }
    }
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()

        let dataTransfer = event.dataTransfer;
        const firstItem = dataTransfer.items[0];
        let file: File | null
        if (firstItem.kind === "file") {
            file = firstItem.getAsFile()
        } else {
            file = dataTransfer.files[0]
        }
        if (file) {
            onUpload(file)
        }
    }

    const handleFileInputChange = (files: FileList | null): void => {
        if (!files) return
        const file = files[0]
        if (!file) return

        const fileInputElement = fileInputRef.current
        if (!fileInputElement) return
        fileInputElement.value = ""

        onUpload(file)
    }


    return <div className={styles.container_upload_fill_sheet}>
        <Button variant="outline-primary" onClick={handleUploadButtonClick}>invulsheet upload</Button>
        <div>
                        <span onClick={handleUploadButtonClick}
                              onDragEnter={handleDragEnter}
                              onDragLeave={handleDragLeave}
                              onDragOver={handleDragOver}
                              onDrop={handleDrop}
                              className={styles.drop_area_upload_fill_sheet + (dragAndDropActive ? ` ${styles.drop_area_upload_fill_sheet_active}` : "")}>
                            <div className={"bi-cloud-upload " + styles.drop_area_upload_fill_sheet_icon}></div>
                            invulsheet upload
                            <div className={styles.drop_area_fill_sheet_subtext}>drag & drop</div>
                        </span>
        </div>
        <input type="file" ref={fileInputRef} className={styles.input_upload_fill_sheet}
               onChange={event => handleFileInputChange(event.target.files)}/>
    </div>

}
