import React, {ReactNode} from "react";
import {CreateCalculationTaskFromFillSheetMutation} from "../../../../../../../../../../services/rostering-api";

type TaskCreationResult = CreateCalculationTaskFromFillSheetMutation["calculationTaskCreateFromFillSheet"]

interface Props {
    violations: TaskCreationResult["violations"]
}

type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

const violationMessage = (violation: ArrayElement<TaskCreationResult["violations"]>): ReactNode => {
    const {violationType, sheetName, columnName} = violation
    switch (violationType) {
        case "SHEET_NOT_FOUND":
            return `Ontbrekend tabblad: "${sheetName}"`
        case "HEADER_ROW_NOT_FOUND":
            return `Rij met kolom-namen ontbreekt in tabblad "${sheetName}"`
        case "DUPLICATE_COLUMN":
            return `De kolom "${columnName}" komt meer dan 1 keer voor in tabblad "${sheetName}"`
        case "COLUMN_NOT_FOUND":
            return `De kolom "${columnName}" ontbreekt in tabblad "${sheetName}"`
        case "STRING_CELL_VALUE_INVALID":
            return dataTypeViolationMessage(violation, "tekst")
        case "BOOLEAN_CELL_VALUE_INVALID":
            return dataTypeViolationMessage(violation, "ja/nee")
        case "NUMBER_CELL_VALUE_INVALID":
            return dataTypeViolationMessage(violation, "getal")
        case "NUMBER_CELL_VALUE_TOO_LOW":
            return `Het ingevoerde getal in ${cellLocationDescription(violation)} is te laag`
        case "DATE_TIME_CELL_VALUE_INVALID":
            return dataTypeViolationMessage(violation, "datum met tijdstip")
        case "DATE_CELL_VALUE_INVALID":
            return dataTypeViolationMessage(violation, "datum")
        case "TIME_CELL_VALUE_INVALID":
            return dataTypeViolationMessage(violation, "tijdstip")
        case "SERVICE_TYPE_CELL_VALUE_INVALID":
            return dataTypeViolationMessage(violation, "bekend diensttype")
        case "DATE_TIME_CELL_VALUE_TOO_EARLY":
            return `De ingevoerde datum met tijdstip in ${cellLocationDescription(violation)} is te vroeg`
        case "TIME_CELL_VALUE_TOO_EARLY":
            return `Het ingevoerde tijdstip in ${cellLocationDescription(violation)} is te vroeg`
        case "DUPLICATE_WORKER":
            return `In ${cellLocationDescription(violation)} is een al eerder ingevoerde kracht nogmaals ingevoerd`
        case "REQUIRED_CELL_VALUE_BLANK":
            return `Invoer ontbreekt in ${cellLocationDescription(violation)}`
        default:
            return "N/A"
    }
}

const dataTypeViolationMessage = (violation: ArrayElement<TaskCreationResult["violations"]>, typeIndication: string): string =>
    `In ${cellLocationDescription(violation)} is geen ${typeIndication} ingevoerd`

const cellLocationDescription = (violation: ArrayElement<TaskCreationResult["violations"]>): string => {
    const {sheetName, columnName, rowNumber} = violation
    return `tabblad "${sheetName}", kolom "${columnName}", rij ${rowNumber}`
}

export default function FillSheetInvalid({violations}: Props) {
    return <div>
        <p>
            In het bestand zijn de onderstaande fouten aangetroffen:
        </p>
        <ul>
            {violations.map((violation, index) =>
                <li key={index}>{violationMessage(violation)}</li>
            )}
        </ul>
    </div>
}
