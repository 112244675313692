import {PropsWithChildren, ReactNode} from "react";
import styles from "./styles.module.scss";
import Button from "../../../../../../components/Button";
import ErrorMessages from "../../../../../../components/ErrorMessages";

interface Props {
    className?: string
    header?: ReactNode
    errorMessages?: Array<string>
    previousDisabled?: boolean
    nextButtonText?: string
    nextDisabled?: boolean
    onPrevious?: () => void
    onNext: () => void
}

export default function Step(
    {
        className,
        header,
        children,
        errorMessages = [],
        previousDisabled = false,
        nextButtonText = "volgende",
        nextDisabled = false,
        onPrevious,
        onNext
    }: PropsWithChildren<Props>
) {
    return <div className={className}>
        {header && <h2 className={styles.header}>{header}</h2>}
        <div className="d-flex justify-content-center">{children}</div>
        <ErrorMessages messages={errorMessages}/>
        <div className={styles.container_navigation_buttons}>
            <Button variant="primary" disabled={previousDisabled} onClick={onPrevious}>vorige</Button>
            <Button variant="primary" disabled={nextDisabled} onClick={onNext}>{nextButtonText}</Button>
        </div>
    </div>
}
