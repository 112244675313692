import styles from "./styles.module.scss";
import ButtonsAddRemove from "./components/ButtonsAddRemove";
import {PropsWithChildren} from "react";

interface Props {
    removeDisabled?: boolean
    onRemove?: () => void
    onAdd: () => void
}

export default function RosterShiftContainer({removeDisabled, onRemove, onAdd, children}: PropsWithChildren<Props>) {

    return <div className={styles.container_roster_shift}>
        {children}
        <ButtonsAddRemove removeDisabled={removeDisabled} onRemoveButtonClick={onRemove} onAddButtonClick={onAdd}/>
    </div>

}
