import {Form} from "react-bootstrap";
import styles from "./styles.module.scss"
import {ReactNode} from "react";

interface Props {
    id: string;
    labelText: ReactNode;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

export default function ServiceTypeCheckbox({labelText, id, checked = false, onChange}: Props) {
    return <Form.Check className={styles.container_check} inline id={id}>
        <Form.Check.Label className={styles.label_check}>
            <span>{labelText}</span>
            <Form.Check.Input type="checkbox" className={styles.input_check} checked={checked}
                              onChange={event => onChange(event.currentTarget.checked)}/>
        </Form.Check.Label>
    </Form.Check>
}
