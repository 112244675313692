import {makeVar, useReactiveVar} from "@apollo/client";
import React, {useEffect, useRef} from "react";
import {DateRangeViolations} from "./components/ProblemTemplateWizard/components/StepDateRange";
import {
    DateRange,
    DateRangeInput,
    RosteringProblemTemplateState,
    ServiceType,
    useCreateRosteringProblemTemplateMutation,
    useGenerateFillSheetFromProblemTemplateMutation,
    useSpecifyProblemTemplateDateRangeMutation,
    useSpecifyProblemTemplateNumberOfWorkersMutation,
    useSpecifyProblemTemplateRosterMutation,
    useSpecifyProblemTemplateServiceTypesMutation,
    WeeklyRecurringRoster,
    WeeklyRecurringRosterInput
} from "../../services/rostering-api";
import ProblemTemplateWizard, {WizardInput, WizardViolations} from "./components/ProblemTemplateWizard";
import {saveAs} from "file-saver";
import {Buffer} from "buffer"
import ErrorMessages from "../../components/ErrorMessages";
import {useNavigate, useParams} from "react-router-dom";
import {parseWizardStep, routePathOf, WizardStep} from "./services/WizardSteps";

interface SceneState {
    rosteringProblemTemplateId?: string
    templateState?: RosteringProblemTemplateState
    input: WizardInput
    violations: WizardViolations
    errorMessages: Array<string>
}

const initialState: SceneState = {
    rosteringProblemTemplateId: undefined,
    input: {dateRange: {start: "", end: ""}, serviceTypes: [], roster: {shifts: []}, numberOfWorkers: 30},
    violations: {dateRange: {start: [], end: [], general: []}},
    errorMessages: []
}
const sceneStateVar = makeVar<SceneState>(initialState)
const LOCAL_STORAGE_KEY_TEMPLATE_ID = "rosteringProblemTemplateId"

export default function GenerateFillSheet() {
    const sceneState = useReactiveVar(sceneStateVar)
    const {rosteringProblemTemplateId, templateState, input, violations, errorMessages} = sceneState
    const {dateRange: dateRangeInput} = input
    const {step: stepParam} = useParams()
    const navigate = useNavigate()
    const step = stepParam ? parseWizardStep(stepParam) : WizardStep.DATE_RANGE
    const previousErrorMessagesRef = useRef<Array<string>>()
    const previousStepRef = useRef<WizardStep>()
    const [createRosteringProblemTemplate, {
        data: createRosteringProblemTemplateData,
        error: createRosteringProblemTemplateError
    }] = useCreateRosteringProblemTemplateMutation()
    const [specifyProblemTemplateDateRange, {error: specifyDateRangeError}] = useSpecifyProblemTemplateDateRangeMutation()
    const [specifyProblemTemplateServiceTypes, {error: specifyServiceTypesError}] = useSpecifyProblemTemplateServiceTypesMutation()
    const [specifyProblemTemplateRoster, {error: specifyRosterError}] = useSpecifyProblemTemplateRosterMutation()
    const [specifyProblemTemplateNumberOfWorkers, {error: specifyNumberOfWorkersError}] = useSpecifyProblemTemplateNumberOfWorkersMutation()
    const [generateFillSheet, {
        data: generateFillSheetData,
        error: generateFillSheetError
    }] = useGenerateFillSheetFromProblemTemplateMutation()

    useEffect(
        () => {
            sceneStateVar(initialState)
            const templateIdFromStorage = localStorage.getItem(LOCAL_STORAGE_KEY_TEMPLATE_ID)
            if (templateIdFromStorage) {
                sceneStateVar({...sceneStateVar(), rosteringProblemTemplateId: templateIdFromStorage})
            }
            if (!templateIdFromStorage) {
                // noinspection JSIgnoredPromiseFromCall
                createRosteringProblemTemplate()
            }
        },
        // eslint-disable-next-line
        []
    )

    useEffect(() => {
        if (!createRosteringProblemTemplateData)
            return
        const templateId = createRosteringProblemTemplateData.rosteringProblemTemplateCreate.rosteringProblemTemplateId
        sceneStateVar({...sceneStateVar(), rosteringProblemTemplateId: templateId})
        localStorage.setItem(LOCAL_STORAGE_KEY_TEMPLATE_ID, templateId)
    }, [createRosteringProblemTemplateData])

    useEffect(() => {
        if (!templateState) return
        if (step === WizardStep.DOWNLOAD_FILL_SHEET && templateState.fillSheetAvailable) {
            // noinspection JSIgnoredPromiseFromCall
            generateFillSheet(
                {variables: {input: {rosteringProblemTemplateId: templateState.rosteringProblemTemplateId}}}
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateState, step])

    useEffect(() => {
        if (!templateState) return
        if (step === WizardStep.NUMBER_OF_WORKERS && templateState.numberOfWorkers == null) {
            // noinspection JSIgnoredPromiseFromCall
            specifyProblemTemplateNumberOfWorkers(
                {
                    variables: {
                        input: {
                            rosteringProblemTemplateId: templateState.rosteringProblemTemplateId,
                            numberOfWorkers: input.numberOfWorkers
                        }
                    }
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateState, step, input])


    useEffect(() => {
        const previousErrorMessages = previousErrorMessagesRef.current
        const previousStep = previousStepRef.current

        if (errorMessages.length > 0
            && previousErrorMessages != null
            && previousErrorMessages.length > 0
            && previousStep !== step) {
            sceneStateVar({...sceneStateVar(), errorMessages: []})
        }
        previousErrorMessagesRef.current = errorMessages
        previousStepRef.current = step
    }, [errorMessages, step])

    useEffect(() => {
        if (!createRosteringProblemTemplateError) return
        sceneStateVar({
            ...sceneStateVar(),
            errorMessages: ["Helaas kunnen we op dit moment geen invulsheet voor je maken"]
        })
    }, [createRosteringProblemTemplateError])

    useEffect(() => {
        if (!specifyDateRangeError) return
        sceneStateVar({
            ...sceneStateVar(),
            errorMessages: ["Er is iets misgegaan bij het opslaan van de periode"],
            input: {...input, dateRange: templateState ? dateRangeFromTemplateState(templateState) : input.dateRange}
        })
        navigateToStep(WizardStep.DATE_RANGE)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specifyDateRangeError])


    const dateRangeFromTemplateState = (templateState: RosteringProblemTemplateState): DateRange => templateState.dateRange
        ? {start: templateState.dateRange.start, end: templateState.dateRange.end}
        : initialState.input.dateRange

    const navigateToStep = (step: WizardStep) => {
        navigate(routePathOf(step))
    }

    useEffect(() => {
        if (!specifyServiceTypesError) return
        sceneStateVar({
            ...sceneStateVar(),
            errorMessages: ["Er is iets misgegaan bij het opslaan van de afdelingen"],
            input: {
                ...input,
                serviceTypes: templateState ? serviceTypesFromTemplateState(templateState) : input.serviceTypes
            }
        })
        navigateToStep(WizardStep.SERVICE_TYPE_SELECTION)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specifyServiceTypesError])

    const serviceTypesFromTemplateState = (templateState: RosteringProblemTemplateState): Array<ServiceType> => templateState.serviceTypes
        ? templateState.serviceTypes
        : initialState.input.serviceTypes


    useEffect(() => {
        if (!specifyRosterError) return
        sceneStateVar({
            ...sceneStateVar(),
            errorMessages: ["Er is iets misgegaan bij het opslaan van de diensten"],
            input: {
                ...input,
                roster: templateState ? rosterFromTemplateState(templateState) : input.roster
            }
        })
        navigateToStep(WizardStep.ROSTER)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specifyRosterError])


    const rosterFromTemplateState = (templateState: RosteringProblemTemplateState): WeeklyRecurringRoster => templateState.roster
        ? {
            shifts: templateState.roster.shifts.map(shift => ({
                serviceType: shift.serviceType,
                timeRange: {
                    start: {
                        dayOfWeek: shift.timeRange.start.dayOfWeek,
                        timeOfDay: shift.timeRange.start.timeOfDay
                    },
                    end: {
                        dayOfWeek: shift.timeRange.end.dayOfWeek,
                        timeOfDay: shift.timeRange.end.timeOfDay
                    }
                }
            }))
        }
        : initialState.input.roster


    useEffect(() => {
        if (!specifyNumberOfWorkersError) return
        sceneStateVar({
            ...sceneStateVar(),
            errorMessages: ["Er is iets misgegaan bij het opslaan van het aantal krachten"],
            input: {
                ...input,
                numberOfWorkers: templateState ? numberOfWorkersFromTemplateState(templateState) : input.numberOfWorkers
            }
        })
        navigateToStep(WizardStep.NUMBER_OF_WORKERS)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specifyNumberOfWorkersError])

    const numberOfWorkersFromTemplateState = (templateState: RosteringProblemTemplateState): number => templateState.numberOfWorkers
        ? templateState.numberOfWorkers
        : initialState.input.numberOfWorkers

    useEffect(() => {
        if (!generateFillSheetError) return
        sceneStateVar({
            ...sceneStateVar(),
            errorMessages: ["Er is iets misgegaan bij het maken van de invulsheet"],
        })
        navigateToStep(WizardStep.NUMBER_OF_WORKERS)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateFillSheetError])

    const updateTemplateState = (templateState: RosteringProblemTemplateState) => {
        sceneStateVar({
            ...sceneState,
            errorMessages: [],
            templateState: templateState,
            input: {
                ...sceneState.input,
                dateRange: dateRangeFromTemplateState(templateState),
                serviceTypes: serviceTypesFromTemplateState(templateState),
                roster: rosterFromTemplateState(templateState),
                numberOfWorkers: numberOfWorkersFromTemplateState(templateState)
            }
        })
    }


    const handleTemplateStateRetrievalError = () => {
        // noinspection JSIgnoredPromiseFromCall
        createRosteringProblemTemplate()
    }

    const updateStartDate = (start: string) => updateDateRange({...dateRangeInput, start})

    const updateEndDate = (end: string) => updateDateRange({...dateRangeInput, end})

    const updateDateRange = (updatedInput: DateRangeInput) => {
        if (!rosteringProblemTemplateId) throw new Error("Rostering problem template id not defined")

        const updatedViolations: DateRangeViolations = {start: [], end: [], general: []}

        const startInput = updatedInput.start;
        const endInput = updatedInput.end;
        if (startInput && endInput && (endInput < startInput)) {
            updatedViolations.general.push("Kies een einddatum die op of na de startdatum ligt")
        }
        sceneStateVar({
            ...sceneState,
            input: {...input, dateRange: updatedInput},
            violations: {...violations, dateRange: updatedViolations}
        })
        if (!startInput || !endInput || anyDateRangeViolations(updatedViolations))
            return

        // noinspection JSIgnoredPromiseFromCall
        specifyProblemTemplateDateRange(
            {
                variables: {
                    input: {rosteringProblemTemplateId, dateRange: updatedInput}
                }
            }
        )
    }

    const anyDateRangeViolations = (dateRangeViolations: SceneState["violations"]["dateRange"]) =>
        (dateRangeViolations.start.length + dateRangeViolations.end.length + dateRangeViolations.general.length) > 0

    const updateServiceTypeSelection = (updatedServiceTypes: Array<ServiceType>) => {
        if (!rosteringProblemTemplateId) throw new Error("Rostering problem template id not defined")
        sceneStateVar({
            ...sceneState,
            input: {...input, serviceTypes: updatedServiceTypes},
        })
        // noinspection JSIgnoredPromiseFromCall
        specifyProblemTemplateServiceTypes(
            {variables: {input: {rosteringProblemTemplateId, serviceTypes: updatedServiceTypes}}}
        )
    }

    const updateRoster = (updatedRoster: WeeklyRecurringRosterInput) => {
        if (!rosteringProblemTemplateId) throw new Error("Rostering problem template id not defined")
        sceneStateVar({
            ...sceneState,
            input: {...sceneState.input, roster: updatedRoster}
        })
        // noinspection JSIgnoredPromiseFromCall
        specifyProblemTemplateRoster({variables: {input: {rosteringProblemTemplateId, roster: updatedRoster}}})
    }

    const updateNumberOfWorkers = (updatedNumberOfWorkers: number) => {
        if (!rosteringProblemTemplateId) throw new Error("Rostering problem template id not defined")
        sceneStateVar({...sceneState, input: {...sceneState.input, numberOfWorkers: updatedNumberOfWorkers}})
        // noinspection JSIgnoredPromiseFromCall
        specifyProblemTemplateNumberOfWorkers(
            {variables: {input: {rosteringProblemTemplateId, numberOfWorkers: updatedNumberOfWorkers}}}
        )
    }

    const downloadFillSheet = () => {
        if (!generateFillSheetData) return

        const binaryContent = generateFillSheetData.rosteringProblemTemplateFillSheetGenerate.fillSheetDocument.content
        const buffer = Buffer.from(binaryContent.base64Content, "base64")
        const blob: Blob = new Blob([buffer], {type: binaryContent.mediaType})
        saveAs(blob, binaryContent.suggestedFileName)
    }

    return <React.Fragment>
        {
            errorMessages.length > 0 &&
            <ErrorMessages messages={errorMessages}/>
        }
        {
            rosteringProblemTemplateId &&
            <ProblemTemplateWizard step={step}
                                   rosteringProblemTemplateId={rosteringProblemTemplateId}
                                   templateState={templateState}
                                   input={input}
                                   violations={violations}
                                   fillSheetAvailable={generateFillSheetData != null}
                                   onRosteringTemplateStateReceived={updateTemplateState}
                                   onRosteringTemplateStateRetrievalFailed={handleTemplateStateRetrievalError}
                                   onNavigate={navigateToStep}
                                   onStartDateChange={updateStartDate}
                                   onEndDateChange={updateEndDate}
                                   onServiceTypeSelectionChanged={updateServiceTypeSelection}
                                   onRosterChange={updateRoster}
                                   onNumberOfWorkersChange={updateNumberOfWorkers}
                                   onDownloadFillSheetButtonClick={downloadFillSheet}/>

        }
    </React.Fragment>
}
