import {DayOfWeek} from "../../../../../../../../../../services/rostering-api";
import {Fragment} from "react";

interface Props {
    dayOfWeek: DayOfWeek
}

export default function DayOfWeekText({dayOfWeek}: Props) {
    let text = "N/A"
    switch (dayOfWeek) {
        case DayOfWeek.Monday:
            text = "Maandag"
            break
        case DayOfWeek.Tuesday:
            text = "Dinsdag"
            break
        case DayOfWeek.Wednesday:
            text = "Woensdag"
            break
        case DayOfWeek.Thursday:
            text = "Donderdag"
            break
        case DayOfWeek.Friday:
            text = "Vrijdag"
            break
        case DayOfWeek.Saturday:
            text = "Zaterdag"
            break
        case DayOfWeek.Sunday:
            text = "Zondag"
            break
    }
    return <Fragment>{text}</Fragment>
}
