import {DayOfWeek} from "../../../../services/rostering-api";

export const orderedDaysOfWeek = [
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
    DayOfWeek.Saturday,
    DayOfWeek.Sunday
]

export const nextDayOfWeekAfter = (dayOfWeek: DayOfWeek) => {
    const index = (orderedDaysOfWeek.indexOf(dayOfWeek) + 1) % 7
    return orderedDaysOfWeek[index]
}
