import styles from "./styles.module.scss";

interface Props {
    iconClass: string
    className?: string
    onClick: () => void
}

export default function Button({iconClass, className, onClick}: Props) {
    return <span className={`bi-${iconClass} ${styles.button}${className ? (' ' + className) : ''}`} onClick={onClick}/>
}
