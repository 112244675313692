import styles from "./styles.module.scss";
import {ReactNode} from "react";
import FormGroup from "../../../../../../../../components/FormGroup";

interface Props {
    value?: string;
    violations: Array<string>;
    label?: ReactNode;
    onChange: (value: string) => void;
}

export default function DateInput({value, violations, label, onChange}: Props) {
    return <div className={styles.container_datepicker}>
        <FormGroup label={label} value={value} violations={violations} onChange={onChange} inputType="date"/>
    </div>
}
