import {FormControl, FormGroup as BootstrapFormGroup, FormLabel, FormText} from "react-bootstrap";
import {ReactNode} from "react";

interface Props {
    label: ReactNode
    value?: string
    readOnly?: boolean
    violations: Array<string>
    onChange: (value: string) => void
    inputType?: string
}

export default function FormGroup({label, value, readOnly = false, violations, onChange, inputType = "text"}: Props) {
    return <BootstrapFormGroup>
        <FormLabel>{label}</FormLabel>
        <FormControl type={inputType} value={value} isInvalid={violations.length > 0} readOnly={readOnly}
                     onChange={event => onChange(event.currentTarget.value)}/>
        <FormText className="invalid-feedback">
            {violations.map((violation, index) =>
                <div key={index}>{violation}</div>)}
        </FormText>

    </BootstrapFormGroup>
}
