import React from "react";
import {CreateCalculationTaskFromFillSheetMutation} from "../../../../../../services/rostering-api";
import {Button, Modal} from "react-bootstrap";

type TaskCreationResult = CreateCalculationTaskFromFillSheetMutation["calculationTaskCreateFromFillSheet"]

interface Props {
    creationResult?: TaskCreationResult
    submitCalculationDisabled: boolean
    onSubmitCalculation: () => void
}


export default function Footer({creationResult, onSubmitCalculation, submitCalculationDisabled}: Props) {
    if (creationResult?.success) {
        return <Modal.Footer>
            <Button onClick={onSubmitCalculation} disabled={submitCalculationDisabled}>Start planning</Button>
        </Modal.Footer>
    }
    return null
}
