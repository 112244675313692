import {Dispatch, SetStateAction, useEffect, useState} from "react";

interface Props {
    initialNumberOfWorkers: number
    onMount: (setNumberOfWorkers: Dispatch<SetStateAction<number>>) => void
}


export default function NumberOfWorkersIndication({initialNumberOfWorkers, onMount}: Props) {

    const [numberOfWorkers, setNumberOfWorkers] = useState<number>(initialNumberOfWorkers)

    useEffect(() => {
        onMount(setNumberOfWorkers)
    }, [onMount])

    useEffect(() => {
        setNumberOfWorkers(initialNumberOfWorkers)
    }, [onMount, initialNumberOfWorkers])

    return <h1>{numberOfWorkers}</h1>
}
