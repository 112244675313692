import {Nav} from "react-bootstrap";
import {WizardStep} from "../../../../../../services/WizardSteps";
import {PropsWithChildren, ReactNode} from "react";
import styles from "./styles.module.scss"

interface Props {
    step: WizardStep
    title: ReactNode
    activeStep: WizardStep
}

export default function StepItem({step, title, activeStep, children}: PropsWithChildren<Props>) {

    let className = styles.nav_link
    if (activeStep === step) className += ` ${styles.nav_link_active}`

    return <Nav.Item>
        <Nav.Link eventKey={step} className={className}>
            <div>{title}</div>
            <div>{children}</div>
        </Nav.Link>
    </Nav.Item>

}
