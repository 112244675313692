import {CreateCalculationTaskFromFillSheetMutation} from "../../../../../../../../services/rostering-api";
import React, {ReactElement} from "react";
import FillSheetInvalid from "./components/FillSheetInvalid";

interface Props {
    creationResult?: CreateCalculationTaskFromFillSheetMutation["calculationTaskCreateFromFillSheet"]
}

export default function Failure({creationResult}: Props): ReactElement {
    const contents = () => {
        switch (creationResult?.failureReason) {
            case "FILL_SHEET_NOT_A_VALID_SPREADSHEET":
                return "Het bestand dat je ons stuurde is geen geldig Excel-document (.xlsx)"
            case "FILL_SHEET_DEEMED_INFECTED":
                return "Dit bestand bevat (mogelijk) een virus en wordt daarom geweigerd."
            case "FILL_SHEET_INVALID":
                return <FillSheetInvalid violations={creationResult.violations}/>
            default:
                return "Helaas is er een onverwachte fout opgetreden bij het verwerken van de invulsheet. Je kunt het later nog eens proberen"
        }
    }
    return <React.Fragment>{contents()}</React.Fragment>

}
