import FormGroup from "../../../../../../../../components/FormGroup";
import React from "react";
import ErrorMessages from "../../../../../../../../components/ErrorMessages";
import styles from "./styles.module.scss"

interface Props {
    notificationEmailAddress: string
    violations: Array<string>
    submitCalculationTaskDisabled: boolean
    submitCalculationTaskErrorMessage?: string
    submitCalculationTaskSuccess: boolean
    onNotificationEmailAddressChange: (emailAddress: string) => void
}

export default function Success(
    {
        notificationEmailAddress,
        violations,
        submitCalculationTaskDisabled,
        submitCalculationTaskErrorMessage,
        submitCalculationTaskSuccess,
        onNotificationEmailAddressChange
    }: Props
) {
    const errorMessages = []
    if (submitCalculationTaskErrorMessage) {
        errorMessages.push(submitCalculationTaskErrorMessage)
    }
    let message = "Vul hieronder jouw mailadres in zodat de kabouters je kunnen bereiken als de planning gemaakt is. " +
        "(Het plannen kan namelijk wel wat tijd kosten ; -)"

    if (submitCalculationTaskSuccess) {
        message = "De kabouters zijn aan het werk gezet. Je ontvangt een e-mail als ze klaar zijn"
    }
    return <div><p>{message}</p>
        <ErrorMessages messages={errorMessages} className={styles.error_messages}/>
        <FormGroup label="E-mailadres" value={notificationEmailAddress} violations={violations} inputType="email"
                   readOnly={submitCalculationTaskDisabled}
                   onChange={onNotificationEmailAddressChange}/>
    </div>
}
