import {MouseEventHandler, PropsWithChildren} from "react";
import styles from "./styles.module.scss";
import {Button as BootstrapButton} from "react-bootstrap";
import {ButtonVariant} from "react-bootstrap/types";

interface Props {
    variant?: ButtonVariant;
    onClick?: MouseEventHandler | undefined;
    disabled?: boolean;
}

export default function Button({children, variant, onClick, disabled}: PropsWithChildren<Props>) {
    return <BootstrapButton className={styles.button} variant={variant} onClick={onClick}
                            disabled={disabled}>{children}</BootstrapButton>
}
