import {DayOfWeek, ServiceType, WeeklyRecurringTimeRangeInput} from "../../../../../../../../../../services/rostering-api";
import ServiceTypeText from "../../../../../ServiceTypeText";
import styles from "./styles.module.scss"
import RosterShiftSlider from "./components/RosterShiftSlider";
import RosterShiftPlaceholder from "./components/RosterShiftPlaceholder";

interface Props {
    dayOfWeek: DayOfWeek
    serviceType: ServiceType
    timeRanges: Array<WeeklyRecurringTimeRangeInput>
    onAfterChange: (timeRanges: Array<WeeklyRecurringTimeRangeInput>) => void
}

export default function RosterDayServiceType(
    {
        dayOfWeek,
        serviceType,
        timeRanges,
        onAfterChange
    }: Props
) {

    const handleAfterChange = (index: number, timeRange: WeeklyRecurringTimeRangeInput) => {
        const newTimeRanges = [...timeRanges]
        newTimeRanges[index] = timeRange
        onAfterChange(newTimeRanges)
    }

    const handleRemove = (index: number) => {
        const newTimeRanges = [...timeRanges]
        newTimeRanges.splice(index, 1)
        onAfterChange(newTimeRanges)
    }

    const handleAdd = (index?: number) => {
        const newTimeRanges = [...timeRanges]
        let newTimeRange: WeeklyRecurringTimeRangeInput
        if (index === undefined) {
            newTimeRange = {start: {dayOfWeek, timeOfDay: "06:00"}, end: {dayOfWeek, timeOfDay: "14:00"}}
            newTimeRanges.push(newTimeRange)
        } else {
            newTimeRange = {...timeRanges[index]}
            newTimeRanges.splice(index + 1, 0, newTimeRange)
        }
        onAfterChange(newTimeRanges)
    }

    return <div className="d-flex">
        <div
            className={styles.container_service_type_label + ' ' + styles[`container_service_type_label_${serviceType.toLowerCase()}`]}>
            <ServiceTypeText serviceType={serviceType}/></div>
        <div>
            <div>
                {timeRanges.map((timeRange, index) =>
                    <RosterShiftSlider key={index} timeRange={timeRange}
                                       serviceType={serviceType}
                                       onAfterChange={timeRange => handleAfterChange(index, timeRange)}
                                       onRemove={() => handleRemove(index)}
                                       onAdd={() => handleAdd(index)}/>
                )}
                {
                    timeRanges.length === 0 &&
                    <RosterShiftPlaceholder onAdd={() => handleAdd()}/>
                }
            </div>
        </div>
    </div>
}
