import Step from "../Step";
import styles from "./styles.module.scss";

interface Props {
    downloadDisabled: boolean
    errorMessages: Array<string>
    onPrevious: () => void
    onDownloadButtonClick: () => void
}

export default function StepDownloadFillSheet(
    {
        downloadDisabled,
        errorMessages,
        onPrevious,
        onDownloadButtonClick
    }: Props
) {
    return <Step errorMessages={errorMessages} nextButtonText="download invulsheet" nextDisabled={downloadDisabled}
                 onPrevious={onPrevious}
                 onNext={onDownloadButtonClick}>
        <div className={styles.spacer}/>
    </Step>
}

