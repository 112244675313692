import {ReactNode} from "react";
import styles from "./styles.module.scss"

interface Props {
    children: ReactNode
}

export default function Mark({children}: Props) {
    return <div className={styles.mark}>{children}</div>
}
