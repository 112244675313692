import {
    DayOfWeek,
    ServiceType,
    WeeklyRecurringShiftInput,
    WeeklyRecurringTimeRangeInput
} from "../../../../../../../../services/rostering-api";
import RosterDayServiceType from "./components/RosterDayServiceType";
import styles from "./styles.module.scss"
import Mark from "./components/Mark";
import DayOfWeekText from "./components/DayOfWeekText";
import {orderedServiceTypes} from "../../../../../../services/ServiceTypes";

interface Props {
    dayOfWeek: DayOfWeek
    serviceTypes: Array<ServiceType>
    shifts: Array<WeeklyRecurringShiftInput>
    onAfterChange: (shifts: Array<WeeklyRecurringShiftInput>) => void
}

export default function RosterDay({dayOfWeek, serviceTypes, shifts, onAfterChange}: Props) {

    const handleAfterChange = (serviceType: ServiceType, timeRanges: Array<WeeklyRecurringTimeRangeInput>) => {
        const newShifts = shiftsWithTimeRangesForServiceType(serviceType, timeRanges, shifts)
        onAfterChange(newShifts)
    }

    const shiftsWithTimeRangesForServiceType = (
        serviceType: ServiceType, timeRanges: Array<WeeklyRecurringTimeRangeInput>, original: Array<WeeklyRecurringShiftInput>
    ): Array<WeeklyRecurringShiftInput> => {
        const indexes = original.reduce<Array<number>>(
            (accumulator, element, index) => {
                if (element.serviceType === serviceType) accumulator.push(index)
                return accumulator
            },
            []
        )
        const newShifts = [...original]
        let i = indexes.length
        while (i > timeRanges.length) {
            newShifts.splice(indexes[i - 1], 1)
            i--
        }
        let shiftToAdd: WeeklyRecurringShiftInput | undefined
        timeRanges.forEach((timeRange, index) => {
            const targetIndex = indexes[index]
            if (targetIndex === undefined) {
                shiftToAdd = {serviceType, timeRange}
            } else {
                newShifts[targetIndex] = {...newShifts[targetIndex], timeRange}
            }
        })
        if (shiftToAdd) {
            newShifts.splice(indexes.slice(-1)[0] + 1, 0, shiftToAdd)
        }
        return newShifts
    }

    return <div>
        <div className={styles.container_day_label}><DayOfWeekText dayOfWeek={dayOfWeek}/></div>
        <div className={styles.container_marks}>
            <Mark>06:00</Mark>
            <Mark>14:00</Mark>
            <Mark>22:00</Mark>
            <Mark>06:00</Mark>
        </div>
        {
            orderedServiceTypes.filter(serviceType => serviceTypes.includes(serviceType)).map(serviceType => {
            return <RosterDayServiceType
                dayOfWeek={dayOfWeek}
                key={serviceType}
                serviceType={serviceType}
                timeRanges={shifts.filter(shift => shift.serviceType === serviceType).map(shift => shift.timeRange)}
                onAfterChange={timeRanges => handleAfterChange(serviceType, timeRanges)}
            />;
        })}
    </div>
}
