import {ServiceType} from "../../../../../../services/rostering-api";
import {Fragment} from "react";

interface Props {
    serviceType: ServiceType
}

export default function ServiceTypeText({serviceType}: Props) {
    let text = "N/A"
    switch (serviceType) {
        case ServiceType.Shop:
            text = "Kassa"
            break
        case ServiceType.Bakery:
            text = "Bakery"
            break
    }
    return <Fragment>{text}</Fragment>
}
