import RosterShiftContainer from "../RosterShiftContainer";
import RosterShiftSliderContainer from "../RosterShiftSliderContainer";
import styles from "./styles.module.scss"

interface Props {
    onAdd: () => void
}

export default function RosterShiftPlaceholder({onAdd}: Props) {
    return <RosterShiftContainer removeDisabled onAdd={onAdd}>
        <RosterShiftSliderContainer>
            <div className={styles.placeholder}/>
        </RosterShiftSliderContainer>
    </RosterShiftContainer>
}
