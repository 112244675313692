import styles from "./styles.module.scss";
import {FormText} from "react-bootstrap";

interface Props {
    messages: Array<string>
    className?: string
}

export default function ErrorMessages({messages, className}: Props) {
    return <FormText
        className={"invalid-feedback " + styles.container_error_messages + (className ? ` ${className}` : "")}>
        {messages.map((message, index) => <div key={index}>{message}</div>)}
    </FormText>

}
