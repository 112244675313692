import {Nav} from "react-bootstrap";
import {RosteringProblemTemplateState, RosterServiceTypeSummary} from "../../../../../../services/rostering-api";
import {orderedServiceTypes} from "../../../../services/ServiceTypes";
import ServiceTypeText from "../ServiceTypeText";
import DurationText from "./components/DurationText";
import {parseWizardStep, WizardStep} from "../../../../services/WizardSteps";
import {Link} from "react-router-dom";
import {Path} from "../../../../../../routes";
import styles from "./styles.module.scss"
import StepItem from "./components/StepItem";
import DateText from "./components/DateText";

interface Props {
    activeStep: WizardStep
    templateState?: RosteringProblemTemplateState
    onNavigate: (step: WizardStep) => void
}

export default function WizardNavigation({activeStep, templateState, onNavigate}: Props) {

    const handleSelect = (eventKey: string | null) => {
        if (eventKey == null) return
        onNavigate(parseWizardStep(eventKey))
    }

    return <Nav fill activeKey={activeStep} onSelect={handleSelect} className={`d-none d-md-flex ${styles.nav}`}>
        <Nav.Item><Link to={Path.LANDING_PAGE} className={`nav-link ${styles.landing_page_link}`}>Home</Link></Nav.Item>
        <StepItem step={WizardStep.DATE_RANGE} title="Periode" activeStep={activeStep}>
            <div>
                {templateState?.dateRange?.start
                    ? <DateText date={templateState.dateRange.start}/>
                    : ""
                }
            </div>
            <div>
                {templateState?.dateRange?.end
                    ? <DateText date={templateState.dateRange.end}/>
                    : ""
                }
            </div>
        </StepItem>
        <StepItem step={WizardStep.SERVICE_TYPE_SELECTION} title="Afdelingen" activeStep={activeStep}>
            {
                orderedServiceTypes
                    .filter(serviceType => (templateState?.serviceTypes || []).includes(serviceType))
                    .map(serviceType => <div key={serviceType}><ServiceTypeText serviceType={serviceType}/></div>)
            }
        </StepItem>
        <StepItem step={WizardStep.ROSTER} title="Diensten" activeStep={activeStep}>
            {
                (templateState?.rosterSummary || []).map((serviceTypeSummary: RosterServiceTypeSummary) =>
                    <div key={serviceTypeSummary.serviceType}>
                        <ServiceTypeText
                            serviceType={serviceTypeSummary.serviceType}/> {serviceTypeSummary.numberOfShifts} (<DurationText
                        duration={serviceTypeSummary.totalDuration}/>)
                    </div>
                )
            }
        </StepItem>
        <StepItem step={WizardStep.NUMBER_OF_WORKERS} title="Krachten" activeStep={activeStep}>
            <div>{templateState?.numberOfWorkers || ""}</div>
        </StepItem>
        <StepItem step={WizardStep.DOWNLOAD_FILL_SHEET} title="Download invulsheet" activeStep={activeStep}/>
    </Nav>


}
