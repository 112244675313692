import styles from "./styles.module.scss";
import {ServiceType, WeeklyRecurringTimeRangeInput} from "../../../../../../../../../../../../../../services/rostering-api";
import React, {Dispatch, Fragment, SetStateAction, useEffect, useState} from "react";

interface Props {
    serviceType: ServiceType
    initialTimeRange: WeeklyRecurringTimeRangeInput
    onMount: (setTimeRange: Dispatch<SetStateAction<WeeklyRecurringTimeRangeInput>>) => void
}

export default function TimeRangeIndication({serviceType, initialTimeRange, onMount}: Props) {

    const [timeRange, setTimeRange] = useState<WeeklyRecurringTimeRangeInput>(initialTimeRange)

    useEffect(() => {
        onMount(setTimeRange)
    }, [onMount])

    useEffect(() => {
        setTimeRange(initialTimeRange)
    }, [onMount, initialTimeRange])

    const serviceTypeClassName = styles[`container_${serviceType.toLowerCase()}`]
    return <div className={`${styles.container_time_range_indication} ${serviceTypeClassName}`}>
        <Fragment>
            <span className={`${styles.time_of_day} ${styles.start}`}>{timeRange.start.timeOfDay}</span>
            <span className={`${styles.time_of_day} ${styles.end}`}>{timeRange.end.timeOfDay}</span>
        </Fragment>
    </div>
}
