import RCSlider from "rc-slider"
import "./styles.scss"
import {useEffect, useState} from "react";
import React from "react";

interface Props {
    numberOfWorkers: number
    onChange: (numberOfWorkers: number) => void
    onAfterChange: (numberOfWorkers: number) => void
}

interface State {
    reset: boolean
}

export default function Slider({numberOfWorkers, onChange, onAfterChange}: Props) {

    const [state, setState] = useState<State>()

    useEffect(() => {
        setState({reset: true})
    }, [numberOfWorkers])


    useEffect(() => {
        if (state?.reset) {
            setState({reset: false})
        }
    }, [state])


    return <React.Fragment>
        {
            !state?.reset &&
            <RCSlider className="step-number-of-workers-slider" min={0} max={100} defaultValue={numberOfWorkers}
                      onChange={onChange} onAfterChange={onAfterChange}/>
        }
    </React.Fragment>


}
