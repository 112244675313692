import {Modal} from "react-bootstrap";
import {
    CreateCalculationTaskFromFillSheetMutation,
    SubmitCalculationTaskInput
} from "../../../../services/rostering-api";
import React from "react";
import Header from "./components/Header";
import Body, {SubmitCalculationTaskViolations} from "./components/Body";
import Footer from "./components/Footer";

export type {SubmitCalculationTaskViolations} from "./components/Body"

type TaskCreationResult = CreateCalculationTaskFromFillSheetMutation["calculationTaskCreateFromFillSheet"]


interface Props {
    visible: boolean
    loading: boolean
    creationResult?: TaskCreationResult
    submitCalculationTaskInput?: SubmitCalculationTaskInput
    submitCalculationTaskViolations: SubmitCalculationTaskViolations
    submitCalculationTaskDisabled: boolean
    submitCalculationTaskErrorMessage?: string
    submitCalculationTaskSuccess: boolean
    onDismiss: () => void
    onNotificationEmailAddressChange: (emailAddress: string) => void
    onSubmitCalculation: () => void
}


export default function CalculationTaskCreationFeedbackModal(
    {
        visible,
        creationResult,
        submitCalculationTaskInput,
        submitCalculationTaskViolations,
        submitCalculationTaskDisabled,
        submitCalculationTaskErrorMessage,
        submitCalculationTaskSuccess,
        loading,
        onDismiss,
        onNotificationEmailAddressChange,
        onSubmitCalculation
    }: Props
) {

    return <Modal show={visible} size="xl" onHide={onDismiss}>
        <Header loading={loading} creationResult={creationResult}></Header>
        <Body loading={loading} creationResult={creationResult} submitCalculationTaskInput={submitCalculationTaskInput}
              submitCalculationTaskViolations={submitCalculationTaskViolations}
              submitCalculationTaskDisabled={submitCalculationTaskDisabled}
              submitCalculationTaskErrorMessage={submitCalculationTaskErrorMessage}
              submitCalculationTaskSuccess={submitCalculationTaskSuccess}
              onNotificationEmailAddressChange={onNotificationEmailAddressChange}/>
        <Footer creationResult={creationResult} submitCalculationDisabled={submitCalculationTaskDisabled}
                onSubmitCalculation={onSubmitCalculation}/>
    </Modal>
}
