import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type BinaryContent = {
  __typename?: 'BinaryContent';
  base64Content: Scalars['String'];
  mediaType: Scalars['String'];
  suggestedFileName: Scalars['String'];
};

export enum CalculationTaskCreateFromFillSheetFailureReason {
  FillSheetDeemedInfected = 'FILL_SHEET_DEEMED_INFECTED',
  FillSheetDocumentNotFound = 'FILL_SHEET_DOCUMENT_NOT_FOUND',
  FillSheetInvalid = 'FILL_SHEET_INVALID',
  FillSheetNotAValidSpreadsheet = 'FILL_SHEET_NOT_A_VALID_SPREADSHEET'
}

export type CalculationTaskCreateFromFillSheetResult = {
  __typename?: 'CalculationTaskCreateFromFillSheetResult';
  calculationTaskId?: Maybe<Scalars['ID']>;
  failureReason?: Maybe<CalculationTaskCreateFromFillSheetFailureReason>;
  success: Scalars['Boolean'];
  violations: Array<CalculationTaskCreateFromFillSheetViolation>;
};

export type CalculationTaskCreateFromFillSheetViolation = {
  __typename?: 'CalculationTaskCreateFromFillSheetViolation';
  columnName?: Maybe<Scalars['String']>;
  rowNumber?: Maybe<Scalars['Int']>;
  sheetName: Scalars['String'];
  violationType: CalculationTaskCreateFromFillSheetViolationType;
};

export enum CalculationTaskCreateFromFillSheetViolationType {
  BooleanCellValueInvalid = 'BOOLEAN_CELL_VALUE_INVALID',
  ColumnNotFound = 'COLUMN_NOT_FOUND',
  DateCellValueInvalid = 'DATE_CELL_VALUE_INVALID',
  DateTimeCellValueInvalid = 'DATE_TIME_CELL_VALUE_INVALID',
  DateTimeCellValueTooEarly = 'DATE_TIME_CELL_VALUE_TOO_EARLY',
  DuplicateColumn = 'DUPLICATE_COLUMN',
  DuplicateWorker = 'DUPLICATE_WORKER',
  HeaderRowNotFound = 'HEADER_ROW_NOT_FOUND',
  NumberCellValueInvalid = 'NUMBER_CELL_VALUE_INVALID',
  NumberCellValueTooLow = 'NUMBER_CELL_VALUE_TOO_LOW',
  RequiredCellValueBlank = 'REQUIRED_CELL_VALUE_BLANK',
  ServiceTypeCellValueInvalid = 'SERVICE_TYPE_CELL_VALUE_INVALID',
  SheetNotFound = 'SHEET_NOT_FOUND',
  StringCellValueInvalid = 'STRING_CELL_VALUE_INVALID',
  TimeCellValueInvalid = 'TIME_CELL_VALUE_INVALID',
  TimeCellValueTooEarly = 'TIME_CELL_VALUE_TOO_EARLY'
}

export type CalculationTaskDownloadSolutionResult = {
  __typename?: 'CalculationTaskDownloadSolutionResult';
  content?: Maybe<BinaryContent>;
  resultSheetRemoved: Scalars['Boolean'];
};

export enum CalculationTaskSubmitOutcome {
  SubmissionRefusedDueToTaskExpired = 'SUBMISSION_REFUSED_DUE_TO_TASK_EXPIRED',
  TaskSubmitted = 'TASK_SUBMITTED'
}

export type CalculationTaskSubmitResult = {
  __typename?: 'CalculationTaskSubmitResult';
  outcome: CalculationTaskSubmitOutcome;
};

export type CreateCalculationTaskFromFillSheetInput = {
  base64Content: Scalars['String'];
};

export type DateRange = {
  __typename?: 'DateRange';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type DateRangeInput = {
  end: Scalars['String'];
  start: Scalars['String'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type FillSheetDocument = {
  __typename?: 'FillSheetDocument';
  content: BinaryContent;
  fillSheetId: Scalars['ID'];
};

export type Health = {
  __typename?: 'Health';
  alive: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  calculationTaskCreateFromFillSheet: CalculationTaskCreateFromFillSheetResult;
  calculationTaskSubmit: CalculationTaskSubmitResult;
  rosteringProblemTemplateCreate: RosteringProblemTemplateCreateResult;
  rosteringProblemTemplateDateRangeSpecify: RosteringProblemTemplateDateRangeSpecifyResult;
  rosteringProblemTemplateFillSheetGenerate: RosteringProblemTemplateFillSheetGenerateResult;
  rosteringProblemTemplateNumberOfWorkersSpecify: RosteringProblemTemplateNumberOfWorkersSpecifyResult;
  rosteringProblemTemplateRosterSpecify: RosteringProblemTemplateRosterSpecifyResult;
  rosteringProblemTemplateServiceTypesSpecify: RosteringProblemTemplateServiceTypesSpecifyResult;
};


export type MutationCalculationTaskCreateFromFillSheetArgs = {
  input: CreateCalculationTaskFromFillSheetInput;
};


export type MutationCalculationTaskSubmitArgs = {
  input: SubmitCalculationTaskInput;
};


export type MutationRosteringProblemTemplateDateRangeSpecifyArgs = {
  input: RosteringProblemTemplateDateRangeSpecifyInput;
};


export type MutationRosteringProblemTemplateFillSheetGenerateArgs = {
  input: RosteringProblemTemplateFillSheetGenerateInput;
};


export type MutationRosteringProblemTemplateNumberOfWorkersSpecifyArgs = {
  input: RosteringProblemTemplateNumberOfWorkersSpecifyInput;
};


export type MutationRosteringProblemTemplateRosterSpecifyArgs = {
  input: RosteringProblemTemplateRosterSpecifyInput;
};


export type MutationRosteringProblemTemplateServiceTypesSpecifyArgs = {
  input: RosteringProblemTemplateServiceTypesSpecifyInput;
};

export type Query = {
  __typename?: 'Query';
  calculationTaskDownloadSolution: CalculationTaskDownloadSolutionResult;
  health: Health;
  rosteringProblemTemplateState: RosteringProblemTemplateState;
};


export type QueryCalculationTaskDownloadSolutionArgs = {
  calculationTaskId: Scalars['ID'];
  verificationCode: Scalars['String'];
};


export type QueryRosteringProblemTemplateStateArgs = {
  rosteringProblemTemplateId: Scalars['ID'];
};

export type RosterServiceTypeSummary = {
  __typename?: 'RosterServiceTypeSummary';
  numberOfShifts: Scalars['Int'];
  serviceType: ServiceType;
  totalDuration: Scalars['String'];
};

export type RosteringProblemTemplateCreateResult = {
  __typename?: 'RosteringProblemTemplateCreateResult';
  rosteringProblemTemplateId: Scalars['ID'];
};

export type RosteringProblemTemplateDateRangeSpecifyInput = {
  dateRange: DateRangeInput;
  rosteringProblemTemplateId: Scalars['ID'];
};

export type RosteringProblemTemplateDateRangeSpecifyResult = {
  __typename?: 'RosteringProblemTemplateDateRangeSpecifyResult';
  rosteringProblemTemplateId: Scalars['ID'];
};

export type RosteringProblemTemplateFillSheetGenerateInput = {
  rosteringProblemTemplateId: Scalars['ID'];
};

export type RosteringProblemTemplateFillSheetGenerateResult = {
  __typename?: 'RosteringProblemTemplateFillSheetGenerateResult';
  fillSheetDocument: FillSheetDocument;
};

export type RosteringProblemTemplateNumberOfWorkersSpecifyInput = {
  numberOfWorkers: Scalars['Int'];
  rosteringProblemTemplateId: Scalars['ID'];
};

export type RosteringProblemTemplateNumberOfWorkersSpecifyResult = {
  __typename?: 'RosteringProblemTemplateNumberOfWorkersSpecifyResult';
  rosteringProblemTemplateId: Scalars['ID'];
};

export type RosteringProblemTemplateRosterSpecifyInput = {
  roster: WeeklyRecurringRosterInput;
  rosteringProblemTemplateId: Scalars['ID'];
};

export type RosteringProblemTemplateRosterSpecifyResult = {
  __typename?: 'RosteringProblemTemplateRosterSpecifyResult';
  rosteringProblemTemplateId: Scalars['ID'];
};

export type RosteringProblemTemplateServiceTypesSpecifyInput = {
  rosteringProblemTemplateId: Scalars['ID'];
  serviceTypes: Array<ServiceType>;
};

export type RosteringProblemTemplateServiceTypesSpecifyResult = {
  __typename?: 'RosteringProblemTemplateServiceTypesSpecifyResult';
  rosteringProblemTemplateId: Scalars['ID'];
};

export type RosteringProblemTemplateState = {
  __typename?: 'RosteringProblemTemplateState';
  dateRange?: Maybe<DateRange>;
  fillSheetAvailable: Scalars['Boolean'];
  numberOfWorkers?: Maybe<Scalars['Int']>;
  roster?: Maybe<WeeklyRecurringRoster>;
  rosterSummary?: Maybe<Array<RosterServiceTypeSummary>>;
  rosteringProblemTemplateId: Scalars['ID'];
  serviceTypes: Array<ServiceType>;
};

export enum ServiceType {
  Bakery = 'BAKERY',
  Shop = 'SHOP'
}

export type SubmitCalculationTaskInput = {
  calculationTaskId: Scalars['ID'];
  /** A valid e-mail address according to RFC 5321 */
  notificationEmailAddress: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  rosteringProblemTemplateState: RosteringProblemTemplateState;
};


export type SubscriptionRosteringProblemTemplateStateArgs = {
  rosteringProblemTemplateId: Scalars['ID'];
};

export type WeeklyRecurringRoster = {
  __typename?: 'WeeklyRecurringRoster';
  shifts: Array<WeeklyRecurringShift>;
};

export type WeeklyRecurringRosterInput = {
  shifts: Array<WeeklyRecurringShiftInput>;
};

export type WeeklyRecurringShift = {
  __typename?: 'WeeklyRecurringShift';
  serviceType?: Maybe<ServiceType>;
  timeRange: WeeklyRecurringTimeRange;
};

export type WeeklyRecurringShiftInput = {
  serviceType?: InputMaybe<ServiceType>;
  timeRange: WeeklyRecurringTimeRangeInput;
};

export type WeeklyRecurringTime = {
  __typename?: 'WeeklyRecurringTime';
  dayOfWeek: DayOfWeek;
  timeOfDay: Scalars['String'];
};

export type WeeklyRecurringTimeInput = {
  dayOfWeek: DayOfWeek;
  timeOfDay: Scalars['String'];
};

export type WeeklyRecurringTimeRange = {
  __typename?: 'WeeklyRecurringTimeRange';
  end: WeeklyRecurringTime;
  start: WeeklyRecurringTime;
};

export type WeeklyRecurringTimeRangeInput = {
  end: WeeklyRecurringTimeInput;
  start: WeeklyRecurringTimeInput;
};

export type CreateCalculationTaskFromFillSheetMutationVariables = Exact<{
  input: CreateCalculationTaskFromFillSheetInput;
}>;


export type CreateCalculationTaskFromFillSheetMutation = { __typename?: 'Mutation', calculationTaskCreateFromFillSheet: { __typename?: 'CalculationTaskCreateFromFillSheetResult', success: boolean, calculationTaskId?: string | null, failureReason?: CalculationTaskCreateFromFillSheetFailureReason | null, violations: Array<{ __typename?: 'CalculationTaskCreateFromFillSheetViolation', sheetName: string, rowNumber?: number | null, violationType: CalculationTaskCreateFromFillSheetViolationType, columnName?: string | null }> } };

export type CreateRosteringProblemTemplateMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateRosteringProblemTemplateMutation = { __typename?: 'Mutation', rosteringProblemTemplateCreate: { __typename?: 'RosteringProblemTemplateCreateResult', rosteringProblemTemplateId: string } };

export type DownloadResultSheetQueryVariables = Exact<{
  calculationTaskId: Scalars['ID'];
  verificationCode: Scalars['String'];
}>;


export type DownloadResultSheetQuery = { __typename?: 'Query', calculationTaskDownloadSolution: { __typename?: 'CalculationTaskDownloadSolutionResult', resultSheetRemoved: boolean, content?: { __typename?: 'BinaryContent', suggestedFileName: string, mediaType: string, base64Content: string } | null } };

export type GenerateFillSheetFromProblemTemplateMutationVariables = Exact<{
  input: RosteringProblemTemplateFillSheetGenerateInput;
}>;


export type GenerateFillSheetFromProblemTemplateMutation = { __typename?: 'Mutation', rosteringProblemTemplateFillSheetGenerate: { __typename?: 'RosteringProblemTemplateFillSheetGenerateResult', fillSheetDocument: { __typename?: 'FillSheetDocument', content: { __typename?: 'BinaryContent', suggestedFileName: string, mediaType: string, base64Content: string } } } };

export type GetRosteringProblemTemplateStateQueryVariables = Exact<{
  rosteringProblemTemplateId: Scalars['ID'];
}>;


export type GetRosteringProblemTemplateStateQuery = { __typename?: 'Query', rosteringProblemTemplateState: { __typename?: 'RosteringProblemTemplateState', rosteringProblemTemplateId: string, serviceTypes: Array<ServiceType>, numberOfWorkers?: number | null, fillSheetAvailable: boolean, dateRange?: { __typename?: 'DateRange', start: string, end: string } | null, roster?: { __typename?: 'WeeklyRecurringRoster', shifts: Array<{ __typename?: 'WeeklyRecurringShift', serviceType?: ServiceType | null, timeRange: { __typename?: 'WeeklyRecurringTimeRange', start: { __typename?: 'WeeklyRecurringTime', dayOfWeek: DayOfWeek, timeOfDay: string }, end: { __typename?: 'WeeklyRecurringTime', dayOfWeek: DayOfWeek, timeOfDay: string } } }> } | null, rosterSummary?: Array<{ __typename?: 'RosterServiceTypeSummary', serviceType: ServiceType, numberOfShifts: number, totalDuration: string }> | null } };

export type SpecifyProblemTemplateDateRangeMutationVariables = Exact<{
  input: RosteringProblemTemplateDateRangeSpecifyInput;
}>;


export type SpecifyProblemTemplateDateRangeMutation = { __typename?: 'Mutation', rosteringProblemTemplateDateRangeSpecify: { __typename?: 'RosteringProblemTemplateDateRangeSpecifyResult', rosteringProblemTemplateId: string } };

export type SpecifyProblemTemplateNumberOfWorkersMutationVariables = Exact<{
  input: RosteringProblemTemplateNumberOfWorkersSpecifyInput;
}>;


export type SpecifyProblemTemplateNumberOfWorkersMutation = { __typename?: 'Mutation', rosteringProblemTemplateNumberOfWorkersSpecify: { __typename?: 'RosteringProblemTemplateNumberOfWorkersSpecifyResult', rosteringProblemTemplateId: string } };

export type SpecifyProblemTemplateRosterMutationVariables = Exact<{
  input: RosteringProblemTemplateRosterSpecifyInput;
}>;


export type SpecifyProblemTemplateRosterMutation = { __typename?: 'Mutation', rosteringProblemTemplateRosterSpecify: { __typename?: 'RosteringProblemTemplateRosterSpecifyResult', rosteringProblemTemplateId: string } };

export type SpecifyProblemTemplateServiceTypesMutationVariables = Exact<{
  input: RosteringProblemTemplateServiceTypesSpecifyInput;
}>;


export type SpecifyProblemTemplateServiceTypesMutation = { __typename?: 'Mutation', rosteringProblemTemplateServiceTypesSpecify: { __typename?: 'RosteringProblemTemplateServiceTypesSpecifyResult', rosteringProblemTemplateId: string } };

export type SubmitCalculationTaskMutationVariables = Exact<{
  input: SubmitCalculationTaskInput;
}>;


export type SubmitCalculationTaskMutation = { __typename?: 'Mutation', calculationTaskSubmit: { __typename?: 'CalculationTaskSubmitResult', outcome: CalculationTaskSubmitOutcome } };

export type SubscribeToRosteringProblemTemplateStateSubscriptionVariables = Exact<{
  rosteringProblemTemplateStateId: Scalars['ID'];
}>;


export type SubscribeToRosteringProblemTemplateStateSubscription = { __typename?: 'Subscription', rosteringProblemTemplateState: { __typename?: 'RosteringProblemTemplateState', rosteringProblemTemplateId: string, serviceTypes: Array<ServiceType>, numberOfWorkers?: number | null, fillSheetAvailable: boolean, dateRange?: { __typename?: 'DateRange', start: string, end: string } | null, roster?: { __typename?: 'WeeklyRecurringRoster', shifts: Array<{ __typename?: 'WeeklyRecurringShift', serviceType?: ServiceType | null, timeRange: { __typename?: 'WeeklyRecurringTimeRange', start: { __typename?: 'WeeklyRecurringTime', dayOfWeek: DayOfWeek, timeOfDay: string }, end: { __typename?: 'WeeklyRecurringTime', dayOfWeek: DayOfWeek, timeOfDay: string } } }> } | null, rosterSummary?: Array<{ __typename?: 'RosterServiceTypeSummary', serviceType: ServiceType, numberOfShifts: number, totalDuration: string }> | null } };


export const CreateCalculationTaskFromFillSheetDocument = gql`
    mutation CreateCalculationTaskFromFillSheet($input: CreateCalculationTaskFromFillSheetInput!) {
  calculationTaskCreateFromFillSheet(input: $input) {
    success
    calculationTaskId
    failureReason
    violations {
      sheetName
      rowNumber
      violationType
      columnName
    }
  }
}
    `;
export type CreateCalculationTaskFromFillSheetMutationFn = Apollo.MutationFunction<CreateCalculationTaskFromFillSheetMutation, CreateCalculationTaskFromFillSheetMutationVariables>;

/**
 * __useCreateCalculationTaskFromFillSheetMutation__
 *
 * To run a mutation, you first call `useCreateCalculationTaskFromFillSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalculationTaskFromFillSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalculationTaskFromFillSheetMutation, { data, loading, error }] = useCreateCalculationTaskFromFillSheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCalculationTaskFromFillSheetMutation(baseOptions?: Apollo.MutationHookOptions<CreateCalculationTaskFromFillSheetMutation, CreateCalculationTaskFromFillSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCalculationTaskFromFillSheetMutation, CreateCalculationTaskFromFillSheetMutationVariables>(CreateCalculationTaskFromFillSheetDocument, options);
      }
export type CreateCalculationTaskFromFillSheetMutationHookResult = ReturnType<typeof useCreateCalculationTaskFromFillSheetMutation>;
export type CreateCalculationTaskFromFillSheetMutationResult = Apollo.MutationResult<CreateCalculationTaskFromFillSheetMutation>;
export type CreateCalculationTaskFromFillSheetMutationOptions = Apollo.BaseMutationOptions<CreateCalculationTaskFromFillSheetMutation, CreateCalculationTaskFromFillSheetMutationVariables>;
export const CreateRosteringProblemTemplateDocument = gql`
    mutation CreateRosteringProblemTemplate {
  rosteringProblemTemplateCreate {
    rosteringProblemTemplateId
  }
}
    `;
export type CreateRosteringProblemTemplateMutationFn = Apollo.MutationFunction<CreateRosteringProblemTemplateMutation, CreateRosteringProblemTemplateMutationVariables>;

/**
 * __useCreateRosteringProblemTemplateMutation__
 *
 * To run a mutation, you first call `useCreateRosteringProblemTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRosteringProblemTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRosteringProblemTemplateMutation, { data, loading, error }] = useCreateRosteringProblemTemplateMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateRosteringProblemTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateRosteringProblemTemplateMutation, CreateRosteringProblemTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRosteringProblemTemplateMutation, CreateRosteringProblemTemplateMutationVariables>(CreateRosteringProblemTemplateDocument, options);
      }
export type CreateRosteringProblemTemplateMutationHookResult = ReturnType<typeof useCreateRosteringProblemTemplateMutation>;
export type CreateRosteringProblemTemplateMutationResult = Apollo.MutationResult<CreateRosteringProblemTemplateMutation>;
export type CreateRosteringProblemTemplateMutationOptions = Apollo.BaseMutationOptions<CreateRosteringProblemTemplateMutation, CreateRosteringProblemTemplateMutationVariables>;
export const DownloadResultSheetDocument = gql`
    query DownloadResultSheet($calculationTaskId: ID!, $verificationCode: String!) {
  calculationTaskDownloadSolution(
    calculationTaskId: $calculationTaskId
    verificationCode: $verificationCode
  ) {
    resultSheetRemoved
    content {
      suggestedFileName
      mediaType
      base64Content
    }
  }
}
    `;

/**
 * __useDownloadResultSheetQuery__
 *
 * To run a query within a React component, call `useDownloadResultSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadResultSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadResultSheetQuery({
 *   variables: {
 *      calculationTaskId: // value for 'calculationTaskId'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useDownloadResultSheetQuery(baseOptions: Apollo.QueryHookOptions<DownloadResultSheetQuery, DownloadResultSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadResultSheetQuery, DownloadResultSheetQueryVariables>(DownloadResultSheetDocument, options);
      }
export function useDownloadResultSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadResultSheetQuery, DownloadResultSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadResultSheetQuery, DownloadResultSheetQueryVariables>(DownloadResultSheetDocument, options);
        }
export type DownloadResultSheetQueryHookResult = ReturnType<typeof useDownloadResultSheetQuery>;
export type DownloadResultSheetLazyQueryHookResult = ReturnType<typeof useDownloadResultSheetLazyQuery>;
export type DownloadResultSheetQueryResult = Apollo.QueryResult<DownloadResultSheetQuery, DownloadResultSheetQueryVariables>;
export const GenerateFillSheetFromProblemTemplateDocument = gql`
    mutation GenerateFillSheetFromProblemTemplate($input: RosteringProblemTemplateFillSheetGenerateInput!) {
  rosteringProblemTemplateFillSheetGenerate(input: $input) {
    fillSheetDocument {
      content {
        suggestedFileName
        mediaType
        base64Content
      }
    }
  }
}
    `;
export type GenerateFillSheetFromProblemTemplateMutationFn = Apollo.MutationFunction<GenerateFillSheetFromProblemTemplateMutation, GenerateFillSheetFromProblemTemplateMutationVariables>;

/**
 * __useGenerateFillSheetFromProblemTemplateMutation__
 *
 * To run a mutation, you first call `useGenerateFillSheetFromProblemTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFillSheetFromProblemTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFillSheetFromProblemTemplateMutation, { data, loading, error }] = useGenerateFillSheetFromProblemTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateFillSheetFromProblemTemplateMutation(baseOptions?: Apollo.MutationHookOptions<GenerateFillSheetFromProblemTemplateMutation, GenerateFillSheetFromProblemTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateFillSheetFromProblemTemplateMutation, GenerateFillSheetFromProblemTemplateMutationVariables>(GenerateFillSheetFromProblemTemplateDocument, options);
      }
export type GenerateFillSheetFromProblemTemplateMutationHookResult = ReturnType<typeof useGenerateFillSheetFromProblemTemplateMutation>;
export type GenerateFillSheetFromProblemTemplateMutationResult = Apollo.MutationResult<GenerateFillSheetFromProblemTemplateMutation>;
export type GenerateFillSheetFromProblemTemplateMutationOptions = Apollo.BaseMutationOptions<GenerateFillSheetFromProblemTemplateMutation, GenerateFillSheetFromProblemTemplateMutationVariables>;
export const GetRosteringProblemTemplateStateDocument = gql`
    query GetRosteringProblemTemplateState($rosteringProblemTemplateId: ID!) {
  rosteringProblemTemplateState(
    rosteringProblemTemplateId: $rosteringProblemTemplateId
  ) {
    rosteringProblemTemplateId
    dateRange {
      start
      end
    }
    serviceTypes
    roster {
      shifts {
        serviceType
        timeRange {
          start {
            dayOfWeek
            timeOfDay
          }
          end {
            dayOfWeek
            timeOfDay
          }
        }
      }
    }
    numberOfWorkers
    fillSheetAvailable
    rosterSummary {
      serviceType
      numberOfShifts
      totalDuration
    }
  }
}
    `;

/**
 * __useGetRosteringProblemTemplateStateQuery__
 *
 * To run a query within a React component, call `useGetRosteringProblemTemplateStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRosteringProblemTemplateStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRosteringProblemTemplateStateQuery({
 *   variables: {
 *      rosteringProblemTemplateId: // value for 'rosteringProblemTemplateId'
 *   },
 * });
 */
export function useGetRosteringProblemTemplateStateQuery(baseOptions: Apollo.QueryHookOptions<GetRosteringProblemTemplateStateQuery, GetRosteringProblemTemplateStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRosteringProblemTemplateStateQuery, GetRosteringProblemTemplateStateQueryVariables>(GetRosteringProblemTemplateStateDocument, options);
      }
export function useGetRosteringProblemTemplateStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRosteringProblemTemplateStateQuery, GetRosteringProblemTemplateStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRosteringProblemTemplateStateQuery, GetRosteringProblemTemplateStateQueryVariables>(GetRosteringProblemTemplateStateDocument, options);
        }
export type GetRosteringProblemTemplateStateQueryHookResult = ReturnType<typeof useGetRosteringProblemTemplateStateQuery>;
export type GetRosteringProblemTemplateStateLazyQueryHookResult = ReturnType<typeof useGetRosteringProblemTemplateStateLazyQuery>;
export type GetRosteringProblemTemplateStateQueryResult = Apollo.QueryResult<GetRosteringProblemTemplateStateQuery, GetRosteringProblemTemplateStateQueryVariables>;
export const SpecifyProblemTemplateDateRangeDocument = gql`
    mutation SpecifyProblemTemplateDateRange($input: RosteringProblemTemplateDateRangeSpecifyInput!) {
  rosteringProblemTemplateDateRangeSpecify(input: $input) {
    rosteringProblemTemplateId
  }
}
    `;
export type SpecifyProblemTemplateDateRangeMutationFn = Apollo.MutationFunction<SpecifyProblemTemplateDateRangeMutation, SpecifyProblemTemplateDateRangeMutationVariables>;

/**
 * __useSpecifyProblemTemplateDateRangeMutation__
 *
 * To run a mutation, you first call `useSpecifyProblemTemplateDateRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpecifyProblemTemplateDateRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [specifyProblemTemplateDateRangeMutation, { data, loading, error }] = useSpecifyProblemTemplateDateRangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpecifyProblemTemplateDateRangeMutation(baseOptions?: Apollo.MutationHookOptions<SpecifyProblemTemplateDateRangeMutation, SpecifyProblemTemplateDateRangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SpecifyProblemTemplateDateRangeMutation, SpecifyProblemTemplateDateRangeMutationVariables>(SpecifyProblemTemplateDateRangeDocument, options);
      }
export type SpecifyProblemTemplateDateRangeMutationHookResult = ReturnType<typeof useSpecifyProblemTemplateDateRangeMutation>;
export type SpecifyProblemTemplateDateRangeMutationResult = Apollo.MutationResult<SpecifyProblemTemplateDateRangeMutation>;
export type SpecifyProblemTemplateDateRangeMutationOptions = Apollo.BaseMutationOptions<SpecifyProblemTemplateDateRangeMutation, SpecifyProblemTemplateDateRangeMutationVariables>;
export const SpecifyProblemTemplateNumberOfWorkersDocument = gql`
    mutation SpecifyProblemTemplateNumberOfWorkers($input: RosteringProblemTemplateNumberOfWorkersSpecifyInput!) {
  rosteringProblemTemplateNumberOfWorkersSpecify(input: $input) {
    rosteringProblemTemplateId
  }
}
    `;
export type SpecifyProblemTemplateNumberOfWorkersMutationFn = Apollo.MutationFunction<SpecifyProblemTemplateNumberOfWorkersMutation, SpecifyProblemTemplateNumberOfWorkersMutationVariables>;

/**
 * __useSpecifyProblemTemplateNumberOfWorkersMutation__
 *
 * To run a mutation, you first call `useSpecifyProblemTemplateNumberOfWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpecifyProblemTemplateNumberOfWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [specifyProblemTemplateNumberOfWorkersMutation, { data, loading, error }] = useSpecifyProblemTemplateNumberOfWorkersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpecifyProblemTemplateNumberOfWorkersMutation(baseOptions?: Apollo.MutationHookOptions<SpecifyProblemTemplateNumberOfWorkersMutation, SpecifyProblemTemplateNumberOfWorkersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SpecifyProblemTemplateNumberOfWorkersMutation, SpecifyProblemTemplateNumberOfWorkersMutationVariables>(SpecifyProblemTemplateNumberOfWorkersDocument, options);
      }
export type SpecifyProblemTemplateNumberOfWorkersMutationHookResult = ReturnType<typeof useSpecifyProblemTemplateNumberOfWorkersMutation>;
export type SpecifyProblemTemplateNumberOfWorkersMutationResult = Apollo.MutationResult<SpecifyProblemTemplateNumberOfWorkersMutation>;
export type SpecifyProblemTemplateNumberOfWorkersMutationOptions = Apollo.BaseMutationOptions<SpecifyProblemTemplateNumberOfWorkersMutation, SpecifyProblemTemplateNumberOfWorkersMutationVariables>;
export const SpecifyProblemTemplateRosterDocument = gql`
    mutation SpecifyProblemTemplateRoster($input: RosteringProblemTemplateRosterSpecifyInput!) {
  rosteringProblemTemplateRosterSpecify(input: $input) {
    rosteringProblemTemplateId
  }
}
    `;
export type SpecifyProblemTemplateRosterMutationFn = Apollo.MutationFunction<SpecifyProblemTemplateRosterMutation, SpecifyProblemTemplateRosterMutationVariables>;

/**
 * __useSpecifyProblemTemplateRosterMutation__
 *
 * To run a mutation, you first call `useSpecifyProblemTemplateRosterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpecifyProblemTemplateRosterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [specifyProblemTemplateRosterMutation, { data, loading, error }] = useSpecifyProblemTemplateRosterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpecifyProblemTemplateRosterMutation(baseOptions?: Apollo.MutationHookOptions<SpecifyProblemTemplateRosterMutation, SpecifyProblemTemplateRosterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SpecifyProblemTemplateRosterMutation, SpecifyProblemTemplateRosterMutationVariables>(SpecifyProblemTemplateRosterDocument, options);
      }
export type SpecifyProblemTemplateRosterMutationHookResult = ReturnType<typeof useSpecifyProblemTemplateRosterMutation>;
export type SpecifyProblemTemplateRosterMutationResult = Apollo.MutationResult<SpecifyProblemTemplateRosterMutation>;
export type SpecifyProblemTemplateRosterMutationOptions = Apollo.BaseMutationOptions<SpecifyProblemTemplateRosterMutation, SpecifyProblemTemplateRosterMutationVariables>;
export const SpecifyProblemTemplateServiceTypesDocument = gql`
    mutation SpecifyProblemTemplateServiceTypes($input: RosteringProblemTemplateServiceTypesSpecifyInput!) {
  rosteringProblemTemplateServiceTypesSpecify(input: $input) {
    rosteringProblemTemplateId
  }
}
    `;
export type SpecifyProblemTemplateServiceTypesMutationFn = Apollo.MutationFunction<SpecifyProblemTemplateServiceTypesMutation, SpecifyProblemTemplateServiceTypesMutationVariables>;

/**
 * __useSpecifyProblemTemplateServiceTypesMutation__
 *
 * To run a mutation, you first call `useSpecifyProblemTemplateServiceTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpecifyProblemTemplateServiceTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [specifyProblemTemplateServiceTypesMutation, { data, loading, error }] = useSpecifyProblemTemplateServiceTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpecifyProblemTemplateServiceTypesMutation(baseOptions?: Apollo.MutationHookOptions<SpecifyProblemTemplateServiceTypesMutation, SpecifyProblemTemplateServiceTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SpecifyProblemTemplateServiceTypesMutation, SpecifyProblemTemplateServiceTypesMutationVariables>(SpecifyProblemTemplateServiceTypesDocument, options);
      }
export type SpecifyProblemTemplateServiceTypesMutationHookResult = ReturnType<typeof useSpecifyProblemTemplateServiceTypesMutation>;
export type SpecifyProblemTemplateServiceTypesMutationResult = Apollo.MutationResult<SpecifyProblemTemplateServiceTypesMutation>;
export type SpecifyProblemTemplateServiceTypesMutationOptions = Apollo.BaseMutationOptions<SpecifyProblemTemplateServiceTypesMutation, SpecifyProblemTemplateServiceTypesMutationVariables>;
export const SubmitCalculationTaskDocument = gql`
    mutation SubmitCalculationTask($input: SubmitCalculationTaskInput!) {
  calculationTaskSubmit(input: $input) {
    outcome
  }
}
    `;
export type SubmitCalculationTaskMutationFn = Apollo.MutationFunction<SubmitCalculationTaskMutation, SubmitCalculationTaskMutationVariables>;

/**
 * __useSubmitCalculationTaskMutation__
 *
 * To run a mutation, you first call `useSubmitCalculationTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCalculationTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCalculationTaskMutation, { data, loading, error }] = useSubmitCalculationTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitCalculationTaskMutation(baseOptions?: Apollo.MutationHookOptions<SubmitCalculationTaskMutation, SubmitCalculationTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitCalculationTaskMutation, SubmitCalculationTaskMutationVariables>(SubmitCalculationTaskDocument, options);
      }
export type SubmitCalculationTaskMutationHookResult = ReturnType<typeof useSubmitCalculationTaskMutation>;
export type SubmitCalculationTaskMutationResult = Apollo.MutationResult<SubmitCalculationTaskMutation>;
export type SubmitCalculationTaskMutationOptions = Apollo.BaseMutationOptions<SubmitCalculationTaskMutation, SubmitCalculationTaskMutationVariables>;
export const SubscribeToRosteringProblemTemplateStateDocument = gql`
    subscription SubscribeToRosteringProblemTemplateState($rosteringProblemTemplateStateId: ID!) {
  rosteringProblemTemplateState(
    rosteringProblemTemplateId: $rosteringProblemTemplateStateId
  ) {
    rosteringProblemTemplateId
    dateRange {
      start
      end
    }
    serviceTypes
    roster {
      shifts {
        serviceType
        timeRange {
          start {
            dayOfWeek
            timeOfDay
          }
          end {
            dayOfWeek
            timeOfDay
          }
        }
      }
    }
    numberOfWorkers
    fillSheetAvailable
    rosterSummary {
      serviceType
      numberOfShifts
      totalDuration
    }
  }
}
    `;

/**
 * __useSubscribeToRosteringProblemTemplateStateSubscription__
 *
 * To run a query within a React component, call `useSubscribeToRosteringProblemTemplateStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToRosteringProblemTemplateStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToRosteringProblemTemplateStateSubscription({
 *   variables: {
 *      rosteringProblemTemplateStateId: // value for 'rosteringProblemTemplateStateId'
 *   },
 * });
 */
export function useSubscribeToRosteringProblemTemplateStateSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToRosteringProblemTemplateStateSubscription, SubscribeToRosteringProblemTemplateStateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToRosteringProblemTemplateStateSubscription, SubscribeToRosteringProblemTemplateStateSubscriptionVariables>(SubscribeToRosteringProblemTemplateStateDocument, options);
      }
export type SubscribeToRosteringProblemTemplateStateSubscriptionHookResult = ReturnType<typeof useSubscribeToRosteringProblemTemplateStateSubscription>;
export type SubscribeToRosteringProblemTemplateStateSubscriptionResult = Apollo.SubscriptionResult<SubscribeToRosteringProblemTemplateStateSubscription>;