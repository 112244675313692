import React from "react";
import {
    CreateCalculationTaskFromFillSheetMutation,
    SubmitCalculationTaskInput
} from "../../../../../../services/rostering-api";
import {Modal} from "react-bootstrap";
import Loading from "./components/Loading";
import Failure from "./components/Failure";
import Success from "./components/Success";


export interface SubmitCalculationTaskViolations {
    notificationEmailAddress: Array<string>
}

interface Props {
    loading: boolean
    creationResult?: CreateCalculationTaskFromFillSheetMutation["calculationTaskCreateFromFillSheet"]
    submitCalculationTaskInput?: SubmitCalculationTaskInput
    submitCalculationTaskViolations: SubmitCalculationTaskViolations
    submitCalculationTaskDisabled: boolean
    submitCalculationTaskErrorMessage?: string
    submitCalculationTaskSuccess: boolean
    onNotificationEmailAddressChange: (emailAddress: string) => void
}

export default function Body(
    {
        loading,
        creationResult,
        submitCalculationTaskInput,
        submitCalculationTaskViolations,
        submitCalculationTaskDisabled,
        submitCalculationTaskErrorMessage,
        submitCalculationTaskSuccess,
        onNotificationEmailAddressChange
    }: Props
) {
    const bodyContents = () => {
        if (loading) {
            return <Loading/>
        }
        if (creationResult?.success && submitCalculationTaskInput) {
            return <Success notificationEmailAddress={submitCalculationTaskInput.notificationEmailAddress}
                            violations={submitCalculationTaskViolations.notificationEmailAddress}
                            submitCalculationTaskDisabled={submitCalculationTaskDisabled}
                            submitCalculationTaskErrorMessage={submitCalculationTaskErrorMessage}
                            submitCalculationTaskSuccess={submitCalculationTaskSuccess}
                            onNotificationEmailAddressChange={onNotificationEmailAddressChange}/>
        }
        return <Failure creationResult={creationResult}/>
    }
    return <Modal.Body>{bodyContents()}</Modal.Body>

}
