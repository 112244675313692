import Step from "../Step";
import {Dispatch, SetStateAction} from "react";
import NumberOfWorkersIndication from "./components/NumberOfWorkersIndication";
import Slider from "./components/Slider";
import styles from "./styles.module.scss"

interface Props {
    input: number
    onChange: (input: number) => void
    onPrevious: () => void
    onNext: () => void
}

export default function StepNumberOfWorkers({input, onChange, onPrevious, onNext}: Props) {
    let setIndicatedNumberOfWorkers: Dispatch<SetStateAction<number>> | undefined
    const handleNumberOfWorkersIndicationMount = (setNumberOfWorkers: Dispatch<SetStateAction<number>>) => {
        setIndicatedNumberOfWorkers = setNumberOfWorkers
    }

    const handleChange = (numberOfWorkers: number) => {
        if (!setIndicatedNumberOfWorkers) return
        setIndicatedNumberOfWorkers(numberOfWorkers)
    }

    return <Step header="Hoeveel krachten worden gepland?" onPrevious={onPrevious}
                 onNext={onNext}>
        <div className={styles.container_step_number_of_workers}>
            <NumberOfWorkersIndication initialNumberOfWorkers={input} onMount={handleNumberOfWorkersIndicationMount}/>
            <Slider numberOfWorkers={input} onChange={handleChange} onAfterChange={onChange}/>
        </div>
    </Step>
}
