import styles from "./styles.module.scss";
import Button from "./components/Button";

interface Props {
    removeDisabled?: boolean
    onRemoveButtonClick?: () => void
    onAddButtonClick: () => void
}

export default function ButtonsAddRemove(
    {
        removeDisabled = false,
        onRemoveButtonClick = () => {
            // ignore
        },
        onAddButtonClick
    }: Props) {
    return <div className={styles.container_buttons_add_remove}>
        <Button iconClass="x-circle-fill"
                className={`${styles.button_slider_remove}${removeDisabled ? (' ' + styles.button_disabled) : ''}`}
                onClick={onRemoveButtonClick}/>
        <Button iconClass="plus-circle-fill" className={styles.button_slider_add} onClick={onAddButtonClick}/>
    </div>
}
