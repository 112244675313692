import {Fragment} from "react";
import {Duration} from "@js-joda/core";

interface Props {
    duration: string
}

export default function DurationText({duration}: Props) {
    const parsedDuration = Duration.parse(duration)
    return <Fragment>{parsedDuration.toHours()}h {parsedDuration.toMinutes() % 60}m</Fragment>
}
