import Step from "../Step";
import {
    DayOfWeek,
    ServiceType,
    WeeklyRecurringRosterInput,
    WeeklyRecurringShiftInput
} from "../../../../../../services/rostering-api";
import RosterDay from "./components/RosterDay";
import styles from "./styles.module.scss"
import {orderedDaysOfWeek} from "../../../../services/DaysOfWeek";

interface Props {
    serviceTypes: Array<ServiceType>
    input: WeeklyRecurringRosterInput
    onAfterChange: (input: WeeklyRecurringRosterInput) => void
    onPrevious: () => void
    onNext: () => void
}

export default function StepRoster({serviceTypes, input, onAfterChange, onPrevious, onNext}: Props) {

    const handleAfterChange = (dayOfWeek: DayOfWeek, shifts: Array<WeeklyRecurringShiftInput>) => {
        const newInput = inputWithShiftsForDayOfWeek(dayOfWeek, shifts, input)
        onAfterChange(newInput)
    }

    const inputWithShiftsForDayOfWeek = (
        dayOfWeek: DayOfWeek, shifts: Array<WeeklyRecurringShiftInput>, original: WeeklyRecurringRosterInput
    ) => {
        const indexes = original.shifts.reduce<Array<number>>((accumulator, element, index) => {
            if (element.timeRange.start.dayOfWeek === dayOfWeek) accumulator.push(index)
            return accumulator
        }, [])
        const newShifts = [...original.shifts]
        let i = indexes.length
        while (i > shifts.length) {
            newShifts.splice(indexes[i - 1], 1)
            i--
        }
        let shiftToAdd: WeeklyRecurringShiftInput | undefined
        shifts.forEach((shift, index) => {
            const targetIndex = indexes[index]
            if (targetIndex === undefined) {
                shiftToAdd = shift
            } else {
                newShifts[targetIndex] = shift
            }
        })
        if (shiftToAdd) {
            newShifts.splice(indexes.slice(-1)[0] + 1, 0, shiftToAdd)
        }
        return {...original, shifts: newShifts}
    }


    return <Step className={styles.step} header="Welke diensten zijn er?" onPrevious={onPrevious} onNext={onNext}>
        <div>
            {orderedDaysOfWeek.map(dayOfWeek =>
                <RosterDay key={dayOfWeek}
                           dayOfWeek={dayOfWeek}
                           serviceTypes={serviceTypes}
                           shifts={input.shifts.filter(shift => shift.timeRange.start.dayOfWeek === dayOfWeek)}
                           onAfterChange={shifts => handleAfterChange(dayOfWeek, shifts)}/>
            )}
        </div>
    </Step>
}
