import ServiceTypeCheckbox from "./components/ServiceTypeCheckbox";
import {ServiceType} from "../../../../../../services/rostering-api";
import Step from "../Step";
import styles from "./styles.module.scss";
import ServiceTypeText from "../ServiceTypeText";
import {orderedServiceTypes} from "../../../../services/ServiceTypes";


interface Props {
    serviceTypes: Array<ServiceType>
    onServiceTypeSelectionChanged: (serviceTypes: Array<ServiceType>) => void
    onPrevious: () => void
    onNext: () => void
}

export default function StepServiceTypeSelection(
    {serviceTypes, onServiceTypeSelectionChanged, onPrevious, onNext}: Props
) {

    const handleShopSelectionChanged = (selected: boolean) => handleSelectionChanged(ServiceType.Shop, selected)
    const handleBakerySelectionChanged = (selected: boolean) => handleSelectionChanged(ServiceType.Bakery, selected)

    const handleSelectionChanged = (serviceType: ServiceType, selected: boolean) => {
        let newSelection: Array<ServiceType>
        if (selected) {
            newSelection = [...serviceTypes, serviceType]
        } else {
            newSelection = serviceTypes.filter(item => item !== serviceType)
        }
        onServiceTypeSelectionChanged(orderedServiceTypes.filter(serviceType => newSelection.includes(serviceType)))
    }

    return <Step header="Welke afdelingen zijn er?" onPrevious={onPrevious}
                 onNext={onNext}>
        <div className={styles.container_service_type_checkboxes}>
            <ServiceTypeCheckbox labelText={<ServiceTypeText serviceType={ServiceType.Shop}/>} id="shop"
                                 checked={serviceTypes.includes(ServiceType.Shop)}
                                 onChange={handleShopSelectionChanged}/>
            <ServiceTypeCheckbox labelText={<ServiceTypeText serviceType={ServiceType.Bakery}/>} id="bakery"
                                 checked={serviceTypes.includes(ServiceType.Bakery)}
                                 onChange={handleBakerySelectionChanged}/>
        </div>
    </Step>
}
