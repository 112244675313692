import {Path} from "../../../../routes";

export enum WizardStep {
    DATE_RANGE = "date-range",
    SERVICE_TYPE_SELECTION = "service-type-selection",
    ROSTER = "roster",
    NUMBER_OF_WORKERS = "number-of-workers",
    DOWNLOAD_FILL_SHEET = "download-fill-sheet"
}

export function parseWizardStep(input: string): WizardStep {
    switch (input) {
        case WizardStep.DATE_RANGE.toString():
            return WizardStep.DATE_RANGE
        case WizardStep.SERVICE_TYPE_SELECTION.toString():
            return WizardStep.SERVICE_TYPE_SELECTION

        case WizardStep.ROSTER.toString():
            return WizardStep.ROSTER

        case WizardStep.NUMBER_OF_WORKERS.toString():
            return WizardStep.NUMBER_OF_WORKERS

        case WizardStep.DOWNLOAD_FILL_SHEET.toString():
            return WizardStep.DOWNLOAD_FILL_SHEET
        default:
            throw new Error(`Unmapped wizard step ${input}`)
    }
}

export function routePathOf(step: WizardStep): string {
    return Path.GENERATE_FILL_SHEET.replace(/:step/, step)
}
