import {createSliderWithTooltip, Range} from "rc-slider";
import {
    DayOfWeek,
    ServiceType,
    WeeklyRecurringTimeRangeInput
} from "../../../../../../../../../../../../../../services/rostering-api";
import {nextDayOfWeekAfter} from "../../../../../../../../../../../../services/DaysOfWeek";
import RosterShiftSliderContainer from "../../../RosterShiftSliderContainer";

interface Props {
    serviceType: ServiceType
    timeRange: WeeklyRecurringTimeRangeInput
    onChange: (timeRange: WeeklyRecurringTimeRangeInput) => void
    onAfterChange: (timeRange: WeeklyRecurringTimeRangeInput) => void
}

const localTimeFromNumber = (number: number) => {
    const roundedNumber = roundNumberToHalfHours(number)
    const hour = (Math.floor(roundedNumber / 60)) % 24;
    const minute = roundedNumber % 60
    return hour.toString().padStart(2, "0") + ":" + minute.toString().padStart(2, "0")
}

const roundNumberToHalfHours = (number: number): number => {
    const remainder = number % 30
    if (remainder < 15) {
        return number - remainder
    } else {
        return number + (30 - remainder)
    }
}

const numberFromLocalTime = (localTime: string) => {
    const matches = localTime.match(/(\d{2}):(\d{2})/)
    if (!matches) throw new Error(`Could not parse local time ${localTime}`)
    const hour = parseInt(matches[1])
    const minute = parseInt(matches[2])
    return (hour * 60) + minute
}

const numbersFromTimeRange = (timeRange: WeeklyRecurringTimeRangeInput): Array<number> => {
    const startNumber = numberFromLocalTime(timeRange.start.timeOfDay)
    let endNumber = numberFromLocalTime(timeRange.end.timeOfDay)
    if (timeRange.end.dayOfWeek !== timeRange.start.dayOfWeek) {
        endNumber += (60 * 24)
    }
    return [startNumber, endNumber]
}

export default function Slider({serviceType, timeRange, onChange, onAfterChange}: Props) {

    const RangeSlider = createSliderWithTooltip(Range)

    const timeRangeFromNumbers = (numbers: Array<number>): WeeklyRecurringTimeRangeInput => {
        let justUnder24Hours = (23 * 60) + 30;
        let startNumber = Math.min(numbers[0], justUnder24Hours);
        let endNumber = numbers[1];
        if ((endNumber - startNumber) > justUnder24Hours) {
            const originalNumbers = numbersFromTimeRange(timeRange)
            if (originalNumbers[1] === endNumber) {
                startNumber = endNumber - justUnder24Hours
            } else {
                endNumber = startNumber + justUnder24Hours
            }
        }

        const startTimeOfDay = localTimeFromNumber(startNumber)
        const endTimeOfDay = localTimeFromNumber(endNumber)
        const startDayOfWeek = timeRange.start.dayOfWeek
        let endDayOfWeek: DayOfWeek
        if (endNumber < (60 * 24)) {
            endDayOfWeek = startDayOfWeek
        } else {
            endDayOfWeek = nextDayOfWeekAfter(startDayOfWeek)
        }
        return {
            start: {dayOfWeek: startDayOfWeek, timeOfDay: startTimeOfDay},
            end: {dayOfWeek: endDayOfWeek, timeOfDay: endTimeOfDay}
        }
    }

    return <RosterShiftSliderContainer>
        <RangeSlider min={0} max={1980} step={30} pushable={30}
                     defaultValue={numbersFromTimeRange(timeRange)}
                     className={`roster-shift-slider roster-shift-slider-${serviceType.toLowerCase()}`} draggableTrack
                     tipFormatter={localTimeFromNumber}
                     onChange={values => onChange(timeRangeFromNumbers(values))}
                     onAfterChange={values => onAfterChange(timeRangeFromNumbers(values))}/>
    </RosterShiftSliderContainer>
}
