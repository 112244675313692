import {Col, Container, Row} from "react-bootstrap";
import StepDateRange, {DateRangeViolations} from "./components/StepDateRange";
import StepServiceTypeSelection from "./components/StepServiceTypeSelection";
import StepRoster from "./components/StepRoster";
import StepNumberOfWorkers from "./components/StepNumberOfWorkers";
import StepDownloadFillSheet from "./components/StepDownloadFillSheet";
import {
    DateRangeInput,
    RosteringProblemTemplateState,
    ServiceType,
    useGetRosteringProblemTemplateStateQuery,
    useSubscribeToRosteringProblemTemplateStateSubscription,
    WeeklyRecurringRoster,
    WeeklyRecurringRosterInput
} from "../../../../services/rostering-api";
import React, {useEffect} from "react";
import WizardNavigation from "./components/WizardNavigation";
import {WizardStep} from "../../services/WizardSteps";

export interface WizardInput {
    dateRange: DateRangeInput
    serviceTypes: Array<ServiceType>
    roster: WeeklyRecurringRoster
    numberOfWorkers: number
}

export interface WizardViolations {
    dateRange: DateRangeViolations
}


interface Props {
    step: WizardStep
    rosteringProblemTemplateId: string
    templateState?: RosteringProblemTemplateState
    input: WizardInput
    violations: WizardViolations
    fillSheetAvailable: boolean
    onRosteringTemplateStateReceived: (templateState: RosteringProblemTemplateState) => void
    onRosteringTemplateStateRetrievalFailed: () => void
    onNavigate: (step: WizardStep) => void
    onStartDateChange: (value: string) => void
    onEndDateChange: (value: string) => void
    onServiceTypeSelectionChanged: (serviceTypes: Array<ServiceType>) => void
    onRosterChange: (input: WeeklyRecurringRosterInput) => void
    onNumberOfWorkersChange: (input: number) => void
    onDownloadFillSheetButtonClick: () => void
}

export default function ProblemTemplateWizard(
    {
        rosteringProblemTemplateId,
        templateState,
        step,
        input,
        violations,
        fillSheetAvailable,
        onRosteringTemplateStateReceived,
        onRosteringTemplateStateRetrievalFailed,
        onNavigate,
        onStartDateChange,
        onEndDateChange,
        onServiceTypeSelectionChanged,
        onRosterChange,
        onNumberOfWorkersChange,
        onDownloadFillSheetButtonClick

    }: Props
) {
    const {
        dateRange: dateRangeInput,
        serviceTypes: serviceTypesInput,
        roster: rosterInput,
        numberOfWorkers: numberOfWorkersInput
    } = input
    const {dateRange: dateRangeViolations} = violations
    const {
        data: templateStateData,
        refetch: refetchTemplateState,
        error: templateStateError
    } = useGetRosteringProblemTemplateStateQuery(
        {variables: {rosteringProblemTemplateId: rosteringProblemTemplateId}}
    )
    const {data: templateStateUpdate} = useSubscribeToRosteringProblemTemplateStateSubscription(
        {variables: {rosteringProblemTemplateStateId: rosteringProblemTemplateId}}
    )

    useEffect(() => {
        refetchTemplateState().then(result => {
            onRosteringTemplateStateReceived(result.data.rosteringProblemTemplateState)
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!templateStateData) return
        onRosteringTemplateStateReceived(templateStateData.rosteringProblemTemplateState)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateStateData])


    useEffect(() => {
        if (!templateStateUpdate) return
        onRosteringTemplateStateReceived(templateStateUpdate.rosteringProblemTemplateState)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateStateUpdate])

    useEffect(() => {
        if (!templateStateError) return
        onRosteringTemplateStateRetrievalFailed()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateStateError])

    const downloadErrorMessages = templateState?.fillSheetAvailable === false
        ? ["We kunnen nog geen invulsheet voor je maken, omdat je nog niet bij alle stappen iets hebt ingevuld"]
        : []

    return <React.Fragment>
        <WizardNavigation activeStep={step} templateState={templateState} onNavigate={onNavigate}/>
        <Container fluid>
            <Row>
                <Col xs={12} className="text-center">
                    {
                        step === WizardStep.DATE_RANGE &&
                        <StepDateRange violations={dateRangeViolations} input={dateRangeInput}
                                       onStartDateChange={onStartDateChange} onEndDateChange={onEndDateChange}
                                       onNext={() => onNavigate(WizardStep.SERVICE_TYPE_SELECTION)}/>
                    }
                    {
                        step === WizardStep.SERVICE_TYPE_SELECTION &&
                        <StepServiceTypeSelection serviceTypes={serviceTypesInput}
                                                  onServiceTypeSelectionChanged={onServiceTypeSelectionChanged}
                                                  onPrevious={() => onNavigate(WizardStep.DATE_RANGE)}
                                                  onNext={() => onNavigate(WizardStep.ROSTER)}/>
                    }
                    {
                        step === WizardStep.ROSTER &&
                        <StepRoster input={rosterInput} serviceTypes={serviceTypesInput} onAfterChange={onRosterChange}
                                    onPrevious={() => onNavigate(WizardStep.SERVICE_TYPE_SELECTION)}
                                    onNext={() => onNavigate(WizardStep.NUMBER_OF_WORKERS)}/>
                    }
                    {
                        step === WizardStep.NUMBER_OF_WORKERS &&
                        <StepNumberOfWorkers input={numberOfWorkersInput}
                                             onChange={onNumberOfWorkersChange}
                                             onPrevious={() => onNavigate(WizardStep.ROSTER)}
                                             onNext={() => onNavigate(WizardStep.DOWNLOAD_FILL_SHEET)}/>
                    }
                    {
                        step === WizardStep.DOWNLOAD_FILL_SHEET &&
                        <StepDownloadFillSheet downloadDisabled={!fillSheetAvailable}
                                               errorMessages={downloadErrorMessages}
                                               onPrevious={() => onNavigate(WizardStep.NUMBER_OF_WORKERS)}
                                               onDownloadButtonClick={onDownloadFillSheetButtonClick}/>
                    }
                </Col>
            </Row>
        </Container>
    </React.Fragment>


}
