import {Col, Container, Row} from "react-bootstrap";
import styles from './styles.module.scss';
import {Link} from "react-router-dom";
import {Path} from "../../routes";
import Button from "../../components/Button";
import React, {useEffect} from "react";
import {
    CalculationTaskSubmitOutcome,
    SubmitCalculationTaskInput,
    useCreateCalculationTaskFromFillSheetMutation,
    useSubmitCalculationTaskMutation
} from "../../services/rostering-api";
import {makeVar, useReactiveVar} from "@apollo/client";
import FillSheetUpload from "./components/FillSheetUpload";
import CalculationTaskCreationFeedbackModal, {
    SubmitCalculationTaskViolations
} from "./components/CalculationTaskCreationFeedbackModal";
import {parse} from "smtp-address-parser";

interface SceneState {
    dragAndDropActive: boolean
    showFeedbackModal: boolean
    submitCalculationTaskInput?: SubmitCalculationTaskInput
    submitCalculationTaskViolations: SubmitCalculationTaskViolations
    submitCalculationTaskErrorMessage?: string,
    submitCalculationTaskSuccess: boolean
}

const initialState: SceneState = {
    dragAndDropActive: false,
    showFeedbackModal: false,
    submitCalculationTaskInput: undefined,
    submitCalculationTaskViolations: {notificationEmailAddress: []},
    submitCalculationTaskErrorMessage: undefined,
    submitCalculationTaskSuccess: false
}

const sceneStateVar = makeVar<SceneState>(initialState)

export default function LandingPage() {
    const sceneState = useReactiveVar(sceneStateVar)
    const {
        dragAndDropActive,
        showFeedbackModal,
        submitCalculationTaskInput,
        submitCalculationTaskViolations,
        submitCalculationTaskErrorMessage,
        submitCalculationTaskSuccess
    } = sceneState
    const [createCalculationTask, {
        data: createCalculationTaskData,
        loading: createCalculationTaskLoading,
        error: createCalculationTaskError,
        reset: createCalculationReset
    }] = useCreateCalculationTaskFromFillSheetMutation()

    const [submitCalculationTask, {
        data: submitCalculationTaskData,
        loading: submitCalculationTaskLoading,
        error: submitCalculationTaskError,
        reset: submitCalculationReset
    }] = useSubmitCalculationTaskMutation()

    useEffect(() => {
        sceneStateVar(initialState)
    }, [])

    useEffect(() => {
        const currentState = sceneStateVar()
        const calculationTaskId = createCalculationTaskData?.calculationTaskCreateFromFillSheet?.calculationTaskId
        sceneStateVar({
            ...currentState,
            submitCalculationTaskInput: calculationTaskId
                ? {calculationTaskId, notificationEmailAddress: ""}
                : undefined,
            showFeedbackModal: createCalculationTaskLoading || !!createCalculationTaskData || !!createCalculationTaskError
        })
    }, [createCalculationTaskData, createCalculationTaskError, createCalculationTaskLoading])

    useEffect(() => {
        if (!submitCalculationTaskError) return
        sceneStateVar({
            ...sceneStateVar(),
            submitCalculationTaskErrorMessage: "Er is iets misgegaan bij het starten van de berekening. " +
                "Je kunt het nogmaals proberen"
        })
    }, [submitCalculationTaskError])


    useEffect(() => {
        if (!submitCalculationTaskData) return
        const outcome = submitCalculationTaskData.calculationTaskSubmit.outcome;
        sceneStateVar({
            ...sceneStateVar(),
            submitCalculationTaskErrorMessage: outcome === CalculationTaskSubmitOutcome.SubmissionRefusedDueToTaskExpired
                ? "Je upload is te lang geleden en de invulsheet is inmiddels opgeruimd. Je kunt de invulsheet nogmaals uploaden"
                : undefined,
            submitCalculationTaskSuccess: outcome === CalculationTaskSubmitOutcome.TaskSubmitted
        })
    }, [submitCalculationTaskData])


    const handleDragEnter = () => {
        sceneStateVar({...sceneState, dragAndDropActive: true})
    }

    const handleDragLeave = () => {
        sceneStateVar({...sceneState, dragAndDropActive: false})
    }

    const handleUpload = (file: File) => {
        const fileReader = new FileReader()
        fileReader.onload = () => {
            const fileContents = fileReader.result
            if (typeof fileContents !== "string") return
            // noinspection JSIgnoredPromiseFromCall
            createCalculationTask({variables: {input: {base64Content: fileContents.split(",")[1]}}})
        }
        fileReader.readAsDataURL(file)
        sceneStateVar({...sceneState, dragAndDropActive: false})
    }

    const handleFeedbackModalDismiss = () => {
        sceneStateVar(initialState)
        createCalculationReset()
        submitCalculationReset()
    }

    const handleNotificationEmailAddressChange = (emailAddress: string): void => {
        if (!submitCalculationTaskInput)
            throw new Error("Submit calculation task input must be defined on email address change")
        sceneStateVar({
            ...sceneState,
            submitCalculationTaskInput: {...submitCalculationTaskInput, notificationEmailAddress: emailAddress},
            submitCalculationTaskViolations: {...submitCalculationTaskViolations, notificationEmailAddress: []}
        })
    }

    const handleSubmitCalculation = (): void => {
        if (!submitCalculationTaskInput) return

        try {
            parse(submitCalculationTaskInput.notificationEmailAddress)
        } catch (e) {
            sceneStateVar({
                ...sceneState,
                submitCalculationTaskViolations: {
                    ...submitCalculationTaskViolations,
                    notificationEmailAddress: ["Vul een geldig e-mailadres in"]
                }
            })
            return
        }
        // noinspection JSIgnoredPromiseFromCall
        submitCalculationTask({variables: {input: submitCalculationTaskInput}})
    }

    return <Container fluid>
        <Row className={styles.row}>
            <Col xs={12} md={6} xl={4} className={`${styles.section} ${styles.section_main}`}>
                <div className={styles.text_block}>
                    <h4>Plan jij nog zelf?</h4>
                    <p className={styles.text}>Of laat jij liever een planning voor je maken? In 4 eenvoudige stappen
                        kun je van een rooster en beschikbaarheid van je medewerkers een planning laten maken!</p>
                </div>
            </Col>
            <Col xs={12} xl={8}>
                <Row>
                    <Col xs={12} className={styles.section}>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className={styles.text_block}>
                                    <h4>Stap 1: invulsheet genereren</h4>
                                    <p className={styles.text}>Volg de stappen om een invulsheet te genereren. Deze
                                        sheet wordt als
                                        template gebruikt voor de input van de te plannen periode, afdelingen, rooster
                                        en
                                        beschikbaarheid van je krachten.</p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className={styles.container_buttons}>
                                <Link to={Path.GENERATE_FILL_SHEET_START}><Button variant="primary">invulsheet
                                    genereren</Button></Link>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} md={6} className={styles.section}>
                        <div className={styles.text_block}>
                            <h4>Stap 2: invulsheet invullen</h4>
                            <p className={styles.text}>Vul met behulp van Microsoft Excel de invulsheet verder aan met
                                gegevens
                                over de beschikbaarheid van je medewerkers; gewenste afdeling, aantal te werken uren,
                                werktijden, vrije dagen, etc.</p>
                        </div>
                    </Col>
                    <Col xs={12} className={styles.section}>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className={styles.text_block}>
                                    <h4>Stap 3: invulsheet uploaden</h4>
                                    <p className={styles.text}>Nadat de gegevens zijn ingevuld kun je deze uploaden. Op
                                        basis van de
                                        gegevens zal er dan een planning gemaakt worden.</p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className={styles.container_buttons}>

                                <FillSheetUpload dragAndDropActive={dragAndDropActive} onDragEnter={handleDragEnter}
                                                 onDragLeave={handleDragLeave} onUpload={handleUpload}/>
                                <CalculationTaskCreationFeedbackModal
                                    visible={showFeedbackModal}
                                    loading={createCalculationTaskLoading}
                                    creationResult={createCalculationTaskData?.calculationTaskCreateFromFillSheet}
                                    submitCalculationTaskInput={submitCalculationTaskInput}
                                    submitCalculationTaskViolations={submitCalculationTaskViolations}
                                    submitCalculationTaskDisabled={submitCalculationTaskLoading || !!submitCalculationTaskData}
                                    submitCalculationTaskErrorMessage={submitCalculationTaskErrorMessage}
                                    submitCalculationTaskSuccess={submitCalculationTaskSuccess}
                                    onDismiss={handleFeedbackModalDismiss}
                                    onNotificationEmailAddressChange={handleNotificationEmailAddressChange}
                                    onSubmitCalculation={handleSubmitCalculation}
                                />
                            </Col>

                        </Row>
                    </Col>

                    <Col xs={12} md={6} className={styles.section}>
                        <div className={styles.text_block}>
                            <h4>Stap 4: download planning</h4>
                            <p className={styles.text}>Als de planning gemaakt is ontvang je een link naar de download
                                pagina.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
}
