import styles from "./styles.module.scss";
import {ReactNode} from "react";

interface Props {
    children: ReactNode
}

export default function RosterShiftSliderContainer({children}: Props) {
    return <div className={styles.container_slider}>{children}</div>
}
