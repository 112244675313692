import {Fragment} from "react";
import {DateTimeFormatter, LocalDate} from "@js-joda/core";

interface Props {
    date: string
}

export default function DateText({date}: Props) {
    const parsedDate = LocalDate.parse(date)
    return <Fragment>{parsedDate.format(DateTimeFormatter.ofPattern('dd-MM-yyyy'))}</Fragment>

}
