import {ServiceType, WeeklyRecurringTimeRangeInput} from "../../../../../../../../../../../../services/rostering-api";
import "./styles.scss"
import TimeRangeIndication from "./components/TimeRangeIndication";
import Slider from "./components/Slider";
import {Dispatch, SetStateAction} from "react";
import RosterShiftContainer from "../RosterShiftContainer";

interface Props {
    serviceType: ServiceType
    timeRange: WeeklyRecurringTimeRangeInput
    onAfterChange: (timeRange: WeeklyRecurringTimeRangeInput) => void
    onRemove: () => void
    onAdd: () => void
}


export default function RosterShiftSlider({serviceType, timeRange, onAfterChange, onRemove, onAdd}: Props) {

    const handleChange = (timeRange: WeeklyRecurringTimeRangeInput) => {
        if (!setIndicatedTimeRange) return
        setIndicatedTimeRange(timeRange)
    }

    let setIndicatedTimeRange: Dispatch<SetStateAction<WeeklyRecurringTimeRangeInput>> | undefined
    const handleTimeRangeIndicationMount = (setTimeRange: Dispatch<SetStateAction<WeeklyRecurringTimeRangeInput>>) => {
        setIndicatedTimeRange = setTimeRange
    }

    return <RosterShiftContainer onRemove={onRemove} onAdd={onAdd}>
        <TimeRangeIndication serviceType={serviceType} initialTimeRange={timeRange}
                             onMount={handleTimeRangeIndicationMount}/>
        <Slider serviceType={serviceType} timeRange={timeRange} onChange={handleChange} onAfterChange={onAfterChange}/>
    </RosterShiftContainer>
}
